import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

export const MessageSnackbar = ({
  message,
  isOpen,
  handleClose,
  severity = "error",
}: {
  message: string | null;
  isOpen: boolean;
  handleClose: any;
  severity?: AlertColor;
}) => {
  const onClose = () => {
    handleClose();
  };

  if (!message) {
    return null;
  }
  return (
    <div>
      <Snackbar
        data-auto-test-id="global-snackbar"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpen}
        onClose={onClose}
        autoHideDuration={3000}
        sx={{ width: "400px" }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
};
