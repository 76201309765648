import { Button, Box, DialogContentText } from "@mui/material";
import Dialog from "../../../../../common/dialog";

export default function OrdersEmptyDialog({
  handleResetFilter,
  isModalFilterOpened,
}: {
  handleResetFilter: () => void;
  isModalFilterOpened: boolean;
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <Dialog
        dismissable={false}
        isOpen={isModalFilterOpened}
        handleCloseModal={() => void 0}
        content={
          <Box>
            <DialogContentText
              data-auto-test-id="no-orders-found"
              align="center"
            >
              No orders found. Reset filters to see different results.
            </DialogContentText>
          </Box>
        }
        actions={
          <Box sx={{ mx: "auto" }}>
            <Button
              sx={{ mx: "auto", my: "40px", width: 300 }}
              variant="contained"
              onClick={handleResetFilter}
              data-auto-test-id="reset-filters"
            >
              Reset filters
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
