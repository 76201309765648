import { get, put } from "../../http/apiClient";
import appConfig from "../../../settings/appConfig";
import QueuesGroup from "../../../models/queue/queueGroup";
import QueueWithOrdersCountGroup from "../../../models/queue/queueWithOrdersCountGroup";
import Queue from "../../../models/queue/queue";
import ResponseModel from "../../../models/common/responseModel";
import QueuesWithGroupModel from "../../../models/queue/queuesWithGroupModel";

export const getAllQueues = async (): Promise<QueuesGroup[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.GetAll}`
    );
    const queues = response as QueuesGroup[];

    return queues;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQueue = async (
  queueId: string
): Promise<ResponseModel<Queue> | null> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.Get}/${queueId}`
    );
    return response as ResponseModel<Queue>;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQueuesOrdersCount = async (): Promise<
  QueueWithOrdersCountGroup[]
> => {
  try {
    const axiosTimeout = (window._env_.AXIOS_CLIENT_POLLING_TIMEOUT_IN_MS ||
      window._env_.AXIOS_CLIENT_TIMEOUT) as number;

    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.GetQueuesOrdersCount}`,
      axiosTimeout
    );
    const queues = response as QueueWithOrdersCountGroup[];
    return queues;
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const getQueuesByGroupId = async (
  groupId: number
): Promise<ResponseModel<QueuesWithGroupModel>> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.GetQueuesByGroupId}/${groupId}`
    );
    return response as ResponseModel<QueuesWithGroupModel>;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQueuesWithGroup = async (
  queuesWithGroupModel: QueuesWithGroupModel
) => {
  try {
    await put(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.UpdateQueuesWithGroup}`,
      queuesWithGroupModel
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
