import { Box } from "@mui/material";
import React from "react";

export default function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      style={{ display: index == value ? "block" : "none" }}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Box>
  );
}
