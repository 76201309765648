import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Badge,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import FilterInputProps from "../../../../../../../models/filters/filterInputProps";
import formatAttribute from "../../../../../../../common/formatAttribute";
import FilterLookupItem from "../../../../../../../models/filters/filterLookupItem";

import Styles from "./field.style";
import { boldString } from "./Utils";

const DropDownFilter = ({
  handleChange,
  selectedValue,
  options,
  title,
  label,
  id,
}: FilterInputProps) => {
  return (
    <Box>
      {title && (
        <Typography
          data-auto-test-id={formatAttribute(title ?? "") + "-filter-text"}
          align="left"
          sx={Styles.title}
        >
          Select {title}
        </Typography>
      )}
      <Autocomplete
        data-testid="dropdown-filter"
        data-auto-test-id="dropdown-filter"
        id={id}
        value={options.find((d) => d.id.toString() == selectedValue) || null}
        defaultValue={null}
        options={options}
        getOptionLabel={(ot: FilterLookupItem) => ot.name}
        sx={Styles.select}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={(_: any, newValue: any) => {
          if (newValue == null) {
            return;
          }
          handleChange(newValue?.id);
        }}
        renderInput={(props) => (
          <TextField {...props} label={label} variant="outlined" />
        )}
        renderOption={(
          props: object,
          lookupItem: FilterLookupItem,
          { inputValue }
        ) => (
          <Box component="li" sx={Styles.option} {...props} key={lookupItem.id}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={10}
                sx={Styles.alignCenter}
                data-auto-test-id={
                  formatAttribute(title ?? "") +
                  "-filter-item-text" +
                  lookupItem.id
                }
                data-testid={
                  formatAttribute(title ?? "") +
                  "-filter-item-text" +
                  lookupItem.id
                }
                zeroMinWidth
              >
                <Box sx={{ flexDirection: "row", display: "flex" }}>
                  {lookupItem.id.toString() == selectedValue && (
                    <CheckIcon sx={Styles.checkedIcon} />
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: boldString(lookupItem.name, inputValue),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Badge
                  data-auto-test-id={
                    formatAttribute(title ?? "") +
                    "-filter-item-badge" +
                    lookupItem.id
                  }
                  badgeContent={lookupItem.ordersCount.toString()}
                  style={{ wordWrap: "break-word" }}
                  max={Number.MAX_SAFE_INTEGER}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      />
    </Box>
  );
};

export default DropDownFilter;
