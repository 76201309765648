import { GroupItem } from "../../../../../../models/sideBar/groupItem";
import UserRole from "../../../../../../models/user/userRole";
import { Box } from "@mui/material";
import SideBarQueueGroup from "./components/sideBarQueueGroup";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Links } from "../../../../../../common/menuItems";
import AppState from "../../../../../../redux/appStore";
import { useSelector } from "react-redux";

export default function SideBarQueueGroupList({
  Groups,
  userRoles,
}: {
  Groups: GroupItem[];
  userRoles: UserRole[];
}) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const location = useLocation();
  const initialized = useSelector(
    (state: AppState) => state.queueState.initialized
  );

  const handleChange = (panel: string | false) => setExpanded(panel);

  useEffect(() => {
    //if there is only one element in the groups
    if (Groups.length === 1 && (Groups[0].subItems ?? []).length > 1) {
      setExpanded(Groups[0]?.queueGroupId ?? "");
    }
    //expand the current queue group
    else if (Groups.length > 0) {
      let locationPathname = location.pathname;
      //make sure that the routes is in queue details page
      if (
        locationPathname &&
        locationPathname.toLowerCase().indexOf(Links.queue) > -1
      ) {
        let selectedGroup = Groups.find((g) =>
          (g.subItems ?? []).some((i) => i.navigateTo === location.pathname)
        );
        if (selectedGroup) setExpanded(selectedGroup.queueGroupId ?? "");
      }
    }
  }, [initialized, location.pathname]);

  return (
    <Box data-testid="sidebar-queue-group-list">
      {Groups.filter((o) => (o.subItems?.length ?? 0) > 0).map(
        (group: GroupItem, index: number) => (
          <SideBarQueueGroup
            key={group.queueGroupId}
            group={group}
            userRoles={userRoles}
            onAccordionChange={handleChange}
            expanded={expanded === group.queueGroupId}
          />
        )
      )}
    </Box>
  );
}
