import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { GroupItem } from "../../../../../../../models/sideBar/groupItem";
import SideBarItem from "./components/sideBarItem";
import UserRole from "../../../../../../../models/user/userRole";
import Styles from "../../../../sideBar.style";
import { Box } from "@mui/material";
import appConfig from "../../../../../../../settings/appConfig";
import expandIcon from "../../../../../../../assets/sidebarExpand.svg";
import formatAttribute from "../../../../../../../common/formatAttribute";

export default function SideBarQueueGroup({
  expanded,
  group,
  onAccordionChange,
  userRoles,
}: {
  expanded: boolean;
  group: GroupItem;
  onAccordionChange: (_: string | false) => void;
  userRoles: UserRole[];
}) {
  const handleChange =
    (_: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      onAccordionChange(isExpanded ? group.queueGroupId ?? "" : false);
    };
  const isOneItemGroup = group.subItems?.length === 1;
  return !isOneItemGroup ? (
    <Accordion
      expanded={expanded}
      onChange={handleChange(group.queueGroupId ?? "")}
      disableGutters={true}
      data-auto-test-id={
        formatAttribute(group.queueGroupName ?? "") + "-queue-group"
      }
      data-testid="sidebar-queue-group-multiple-queue-container"
    >
      <AccordionSummary
        sx={Styles.queueGroupAccordionSummary}
        expandIcon={<img src={expandIcon} />}
        aria-controls={group.queueGroupId}
        id={group.queueGroupId}
      >
        <Typography
          fontWeight={"bold"}
          data-auto-test-id={
            formatAttribute(group.queueGroupName ?? "") + "-queue-group-text"
          }
        >
          {(group.queueGroupName?.length ?? 0) > appConfig.maxSideBarCharLength
            ? group.queueGroupName
                ?.substring(0, appConfig.maxSideBarCharLength)
                .concat("...")
            : group.queueGroupName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={Styles.noMargin}>
        {(group.subItems ?? [])
          .filter((o) =>
            o.roles?.find((r) => userRoles?.some((w) => w.roleId === r))
          )
          .map((subItem: GroupItem, index: number) => (
            <SideBarItem item={subItem} key={subItem.id} />
          ))}
      </AccordionDetails>
    </Accordion>
  ) : (
    <Box
      sx={Styles.menuItemContainer}
      data-testid="sidebar-queue-group-one-queue-container"
    >
      <SideBarItem item={(group?.subItems as GroupItem[])[0]} />
    </Box>
  );
}
