import { MenuItem } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Links } from "../../../../common/menuItems";
import logoutIcon from "../../../../assets/logout.svg";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { clearAuthInfo } from "../../../../redux/slices/authSlice";
import { removeAuthData } from "../../../../core/services/auth/authService";

export const SignOutButton = ({ dropCloseHandler }: any) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  function handleLogout(
    instance: IPublicClientApplication,
    navigate: NavigateFunction
  ) {
    dropCloseHandler();
    navigate(Links.home);
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });

    removeAuthData();
    dispatch(clearAuthInfo());
  }
  return (
    <MenuItem
      data-auto-test-id="logout"
      data-testid="logout-button"
      onClick={() => handleLogout(instance, navigate)}
      style={{ backgroundColor: "white" }}
    >
      <img src={logoutIcon} alt="logout icon" />
      <Typography color={theme.palette.neutral["400"]} sx={{ ml: "10px" }}>
        Logout
      </Typography>
    </MenuItem>
  );
};
