import { Box, Typography } from "@mui/material";
import folderSuccess from "./../../../../../../assets/folderSuccess.svg";
import EmptyQueueStyles from "./emptyQueue.style";

function EmptyQueue() {
  return (
    <Box>
      <Box sx={EmptyQueueStyles.folderIconContainer}>
        <img src={folderSuccess} />
      </Box>
      <Typography sx={EmptyQueueStyles.underIconText}>
        Hooray! All work under this queue is done!
      </Typography>
      <Typography sx={EmptyQueueStyles.underIconText}>
        Please select another queue.
      </Typography>
    </Box>
  );
}

export default EmptyQueue;
