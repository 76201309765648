import { useMemo } from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Group } from "../../../../models/sideBar/group";
import UserRole from "../../../../models/user/userRole";
import SideBarGroupItems from "./components/sideBarGroupItems";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Styles from "../sideBar.style";
import formatAttribute from "../../../../common/formatAttribute";

export default function SideBarGroup({
  group,
  userRoles,
}: {
  group: Group;
  userRoles: UserRole[];
}) {
  let showHeader = useMemo(
    () => group.groupName !== "",
    [group, group.groupName]
  );
  return (
    <>
      {showHeader && (
        <Accordion expanded={true} disableGutters={true}>
          <AccordionSummary
            style={{ cursor: "default" }}
            sx={Styles.accordionSummary}
            aria-controls={group.groupName}
            id={group.groupName}
          >
            <Typography
              data-auto-test-id={
                formatAttribute(group.groupName ?? "") + "-menu-group-text"
              }
              sx={Styles.accordionTitle}
            >
              {group.groupName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={Styles.noMargin}>
            <List sx={Styles.noMargin} data-testid="sidebar-group">
              <SideBarGroupItems
                isGroupLevelQueue={false}
                items={group.groupItems}
                userRoles={userRoles}
              />
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {!showHeader && (
        <List sx={Styles.noMargin} data-testid="sidebar-group">
          <SideBarGroupItems
            isGroupLevelQueue={false}
            items={group.groupItems}
            userRoles={userRoles}
          />
        </List>
      )}
    </>
  );
}
