import Box from "@mui/material/Box";
import Dialog from "../../../../../common/dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import OrderDoneStyles from "./orderDone.style";
import { Links } from "../../../../../common/menuItems";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { CheckCircle, ThumbUp } from "@mui/icons-material";
import OrderDoneDialogProps from "../../../../../models/order/OrderDoneDialogProps";

export default function OrderDoneDialog({
  orderDoneDialogProps,
}: {
  orderDoneDialogProps: OrderDoneDialogProps;
}) {
  const navigate = useNavigate();

  const getNext = () => {
    orderDoneDialogProps.handleCloseModal();
    orderDoneDialogProps.handleGetOrder();
  };

  const navigateToHome = () => {
    navigate(Links.home);
  };

  const renderOrderNos = (orderNos: string[]) => {
    if (orderNos.length > 1) {
      return orderNos.map(
        (p, index) => `${p}${index !== orderNos.length - 1 ? ", " : ""}`
      );
    } else {
      return orderNos[0];
    }
  };

  const MappedOrderNos = (orderNos?: string[]) => {
    if (!orderNos) {
      return null; // or some default value or message if orderNos is undefined or null
    }

    return (
      <Box component="span" sx={OrderDoneStyles.orderNumber}>
        {renderOrderNos(orderNos)}
      </Box>
    );
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <Dialog
        closable={(orderDoneDialogProps.ordersCount ?? 0) <= 0}
        dismissable={false}
        isOpen={orderDoneDialogProps.isModalOpened}
        handleCloseModal={orderDoneDialogProps.handleCloseModal}
        content={
          <Box sx={OrderDoneStyles.contentContainer}>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                sx={OrderDoneStyles.iconsContainer}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {(orderDoneDialogProps.ordersCount ?? 0) > 0 && (
                  <CheckCircle
                    data-auto-test-id="check-circle-icon"
                    sx={OrderDoneStyles.icon}
                    fontSize="medium"
                  />
                )}
                {(orderDoneDialogProps.ordersCount ?? 0) <= 0 && (
                  <ThumbUp
                    data-auto-test-id="thumb-up-icon"
                    sx={OrderDoneStyles.icon}
                    fontSize="medium"
                  />
                )}
              </Grid>
            </Grid>
            <Typography
              mt={1}
              align="center"
              fontWeight={"bold"}
              sx={OrderDoneStyles.title}
              data-auto-test-id="great-job-text"
            >
              Great Job !
            </Typography>
            {(orderDoneDialogProps.ordersCount ?? 0) > 0 && (
              <Box data-auto-test-id="order-done-dialog-content">
                <DialogContentText
                  align="center"
                  mt={1}
                  data-testid="orderDone"
                >
                  {orderDoneDialogProps?.orderNos != null &&
                  orderDoneDialogProps?.orderNos?.length > 1
                    ? "Orders "
                    : "Order "}
                  {MappedOrderNos(orderDoneDialogProps.orderNos)}
                  {orderDoneDialogProps?.orderNos != null &&
                    orderDoneDialogProps?.orderNos.length > 1 &&
                    " are "}{" "}
                  Done !
                </DialogContentText>
                {orderDoneDialogProps.isSynced &&
                  orderDoneDialogProps.savedUserOrderData &&
                  orderDoneDialogProps.savedUserOrderData?.length > 0 && (
                    <Box mt={2} sx={OrderDoneStyles.greyCard}>
                      <Typography
                        align="center"
                        sx={OrderDoneStyles.filterText}
                      >
                        You're getting the next order based on
                      </Typography>
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <Typography
                            sx={OrderDoneStyles.filterWord}
                            align="center"
                          >
                            Filter
                            <Box component="span" sx={OrderDoneStyles.marks}>
                              :
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item pl={1}>
                          <Typography sx={OrderDoneStyles.orderFilters}>
                            {orderDoneDialogProps.savedUserOrderData
                              .filter((fd) => fd.id)
                              .map((fd) => fd.name)
                              .join(", ")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        align="center"
                        sx={OrderDoneStyles.filterText}
                      >
                        Get Next or close to reset
                      </Typography>
                    </Box>
                  )}
              </Box>
            )}
            {(orderDoneDialogProps.ordersCount ?? 0) <= 0 && (
              <Box data-auto-test-id="finish-order-content">
                <Typography
                  mt={1}
                  align="center"
                  fontWeight={"bold"}
                  sx={OrderDoneStyles.title}
                >
                  Congratulations!
                </Typography>
                <DialogContentText align="center" mt={1}>
                  All orders are completed under this queue!
                </DialogContentText>
              </Box>
            )}
            {(orderDoneDialogProps.ordersCount ?? 0) > 0 &&
              orderDoneDialogProps.filteredOrderCount === 0 &&
              !orderDoneDialogProps.canGetNextOrder && (
                <Box mt={2}>
                  <DialogContentText
                    data-auto-test-id="select-different-filter-text"
                    align="center"
                    mt={1}
                  >
                    No more orders here! Please close and select different
                    filters.
                  </DialogContentText>
                </Box>
              )}
          </Box>
        }
        actions={
          <Grid container sx={OrderDoneStyles.orderActionsContainer}>
            {(orderDoneDialogProps.ordersCount ?? 0) > 0 && (
              <Grid item xs={12}>
                <Button
                  sx={OrderDoneStyles.actionButton}
                  fullWidth
                  onClick={getNext}
                  autoFocus
                  variant="contained"
                  data-auto-test-id="dialog-get-next-order-button"
                  disabled={
                    !(
                      orderDoneDialogProps.canGetNextOrder &&
                      (orderDoneDialogProps.ordersCount ?? 0) > 0 &&
                      !orderDoneDialogProps.isLoading
                    )
                  }
                >
                  {orderDoneDialogProps.isFiltersLoading ? (
                    <CircularProgress
                      data-testid="isFiltersLoading"
                      size={18}
                    />
                  ) : (
                    "Get Next Order"
                  )}
                </Button>
              </Grid>
            )}
            {(orderDoneDialogProps.ordersCount ?? 0) <= 0 && (
              <Grid item xs={12} mt={1}>
                <Button
                  data-auto-test-id="back-to-home-button"
                  sx={OrderDoneStyles.actionButton}
                  fullWidth
                  onClick={navigateToHome}
                  autoFocus
                  variant="contained"
                >
                  Back To Home
                </Button>
              </Grid>
            )}
            {(orderDoneDialogProps.ordersCount ?? 0) > 0 && (
              <Grid item xs={12} mt={1}>
                <Button
                  fullWidth
                  autoFocus
                  onClick={orderDoneDialogProps.handleCloseModal}
                  variant="outlined"
                  data-auto-test-id="dialog-close-button"
                >
                  Close
                </Button>
              </Grid>
            )}
          </Grid>
        }
      />
    </Box>
  );
}
