import { Grid, Typography, Box } from "@mui/material";
import FilterLookupItem from "../../../../../../models/filters/filterLookupItem";
import QueueDetailsStyles from "../../../../queueDetails.style";

const OrderFilterValues = ({
  userOrderFiltersData,
}: {
  userOrderFiltersData: FilterLookupItem[];
}) => {
  return userOrderFiltersData && userOrderFiltersData.length > 0 ? (
    <Grid container mt={2} sx={QueueDetailsStyles.greyCard}>
      <>
        <Grid container>
          <Typography mt={1} px={2} fontWeight={"bold"}>
            Filters
          </Typography>
        </Grid>
        <Grid p={2} container sx={QueueDetailsStyles.filterContainer}>
          {userOrderFiltersData?.map((filter, index) => {
            return (
              filter.id && (
                <Grid
                  key={filter.id.toString()}
                  p={1}
                  m={1}
                  item
                  sx={QueueDetailsStyles.filterContainerItem}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <Box
                        data-auto-test-id={
                          "filter-" + filter.filterId + "-badge"
                        }
                        sx={QueueDetailsStyles.badge}
                      >
                        {filter.ordersCount}
                      </Box>
                    </Grid>
                    <Grid item pl={1}>
                      <Typography
                        data-auto-test-id={
                          "filter-" + filter.filterId + "-text"
                        }
                      >
                        {filter.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      </>
    </Grid>
  ) : null;
};
export default OrderFilterValues;
