import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "./redux/slices/snackbarSlice";
import appConfig from "./settings/appConfig";

interface Props {
  children: ReactNode;
}

function ErrorBoundary(props: Props) {
  const dispatch = useDispatch();
  const handleError = (e: Event) => {
    console.error("Unhandled promise rejection");
    dispatch(showSnackbar(appConfig.ErrorMessages.unHandleErrorMessage));
  };

  useEffect(() => {
    window.onerror = (e) => {
      console.error("Unhandled error");
      dispatch(showSnackbar(appConfig.ErrorMessages.unHandleErrorMessage));
    };

    window.addEventListener("unhandledrejection", handleError);

    return () => {
      window.onerror = null;
      window.removeEventListener("unhandledrejection", handleError);
    };
  }, []);

  return <>{props.children}</>;
}

export default ErrorBoundary;
