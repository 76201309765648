// Styles.ts
import { SxProps } from "@mui/material";

const title: SxProps = {
  color: (theme: any) => theme.palette.neutral["800"],
};

const contentContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  minWidth: "350px",
  minHeight: "100px",
};

const iconsContainer: SxProps = {
  width: "56px",
  height: "56px",
  borderRadius: "32px",
  background: "#FED7AA",
};

const icon: SxProps = {
  color: "#F5831F",
};

const orderActionsContainer: SxProps = {
  margin: "0 16px 16px 16px",
};

const actionButton: SxProps = {
  color: (theme: any) => theme.palette.common.white,
  "&:disabled": {
    color: (theme: any) => theme.palette.common.white,
  },
};

const classes = {
  title,
  iconsContainer,
  icon,
  contentContainer,
  orderActionsContainer,
  actionButton,
};

export default classes;
