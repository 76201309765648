import { createSlice } from "@reduxjs/toolkit";
import { SnackBarState } from "../../models/store/snackBarState";

const initialState: SnackBarState = {
  showSnackBar: false,
  snackBarMessage: null,
  severity: "error",
};

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      return {
        ...state,
        showSnackBar: true,
        snackBarMessage: action.payload,
        severity: "error",
      };
    },
    hideSnackbar: (state) => {
      return { ...state, showSnackBar: false, snackBarMessage: null };
    },
    showSnackbarBySeverity: (state, action) => {
      return {
        ...state,
        showSnackBar: true,
        snackBarMessage: action.payload.message,
        severity: action.payload.severity,
      };
    },
  },
});

export const { showSnackbar, hideSnackbar, showSnackbarBySeverity } =
  snackBarSlice.actions;

export default snackBarSlice.reducer;
