import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import loaderReducer from "./slices/loaderSlice";
import snackbarReducer from "./slices/snackbarSlice";
import { LoaderState } from "../models/store/loaderState";
import { SnackBarState } from "../models/store/snackBarState";
import { IAuthData } from "../models/user/authData";
import queueReducer from "./slices/queueSlice";
import { QueueState } from "../models/store/queueState";
import userOrdersReducer from "./slices/userOrdersSlice";
import { UserOrdersState } from "../models/store/userOrdersState";
import { UnsavedChangesDataState } from "../models/store/unsavedChangesDataState";
import UnsavedChangesDataSliceReducer from "./slices/unsavedChangesDataSlice";

/**
 * INFO: this type is used by useSelector hook
 * so all reducers inside appStore should have property inside AppState with the same name
 *
 */
export default interface AppState {
  authState: {
    auth: IAuthData;
  };
  loaderState: LoaderState;
  snackbarState: SnackBarState;
  queueState: QueueState;
  userOrdersState: UserOrdersState;
  unsavedChangesDataState: UnsavedChangesDataState;
}

export const appStore = configureStore({
  reducer: {
    authState: authReducer,
    loaderState: loaderReducer,
    snackbarState: snackbarReducer,
    queueState: queueReducer,
    userOrdersState: userOrdersReducer,
    unsavedChangesDataState: UnsavedChangesDataSliceReducer,
  },
});
