import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QueuesGroup from "../../models/queue/queueGroup";
import { QueueState } from "../../models/store/queueState";
const initialState: QueueState = {
  queues: [],
  initialized: false,
};

const queueSlice = createSlice({
  name: "queueStore",
  initialState,
  reducers: {
    setInitialize(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload;
    },
    setQueues(state, action: PayloadAction<QueuesGroup[]>) {
      state.queues = action.payload;
    },
    removeQueue(state, action: PayloadAction<string | undefined>) {
      const index = state.queues.findIndex((q) =>
        q.queues.some((o) => o.queueId == action.payload)
      );
      if (index > -1) {
        const group = state.queues[index];
        let queueIndex = group.queues.findIndex(
          (o) => o.queueId == action.payload
        );
        if (queueIndex > -1) group.queues.splice(queueIndex, 1);
      }
    },
  },
});

export const { setQueues, setInitialize, removeQueue } = queueSlice.actions;
export default queueSlice.reducer;
