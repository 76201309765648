import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
} from "@mui/material";
import ComponentLoader from "../../../../../../common/loader/componentLoader";
import Order from "../../../../../../models/order/order";
import Styles from "../remainingOrdersPanel.style";

export default function RemainingOrders({
  handleChangePage,
  handleChangeRowsPerPage,
  totalRows,
  rowsPerPage,
  pageIdx,
  remainingOrders,
  isLoading,
}: {
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  totalRows: number;
  rowsPerPage: number;
  pageIdx: number;
  remainingOrders: Order[];
  isLoading: boolean;
}) {
  const changeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <ComponentLoader isOpen={isLoading}>
      <TableContainer sx={Styles.tableContainer} component={Paper}>
        <Table
          data-auto-test-id="remaining-order-table"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                data-auto-test-id="order-no-table-title"
                sx={Styles.tableHeader}
              >
                Order Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {remainingOrders.map((row) => (
              <TableRow key={row.orderId} sx={Styles.tableRow}>
                <TableCell component="th" scope="row">
                  <Typography
                    data-auto-test-id={"order-no-table-row" + row.orderNo}
                    sx={Styles.orderNo}
                  >
                    {row.orderNo}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          data-auto-test-id="remaining-order-table-pagination"
          component="div"
          SelectProps={{
            disabled: isLoading,
          }}
          sx={Styles.pagination}
          labelRowsPerPage={"Items per page:"}
          showFirstButton
          showLastButton
          count={totalRows}
          page={totalRows <= 0 ? 0 : pageIdx}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      </TableContainer>
    </ComponentLoader>
  );
}
