import { useEffect, useMemo, useState } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Links } from "../../common/menuItems";
import { isAuthorized } from "../../core/services/auth/authService";
import { RolesEnum } from "../../models/enums/rolesEnum";
import { Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import HomeStyles from "./home.style";
import { useSelector } from "react-redux";
import AppState from "../../redux/appStore";

export default function Home() {
  const navigate = useNavigate();
  const { auth, isLoaderOpen, userOrders } = useSelector((state: AppState) => ({
    auth: state.authState.auth,
    isLoaderOpen: state.loaderState.isLoaderOpen,
    userOrders: state.userOrdersState.userOrders,
  }));
  const { accounts } = useMsal();
  const theme = useTheme();
  const [isRegularUser, setIsRegularUser] = useState<boolean>(false);
  const name = useMemo(() => accounts[0]?.name, [accounts]);

  useEffect(() => {
    setIsRegularUser(isAuthorized(auth?.roles ?? [], RolesEnum.Regular));
  }, [auth]);

  useEffect(() => {
    if (isRegularUser && userOrders && userOrders.queueId) {
      navigate(`${Links.queue}/${userOrders.queueId}`);
    }
  }, [userOrders, isRegularUser]);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {!isLoaderOpen ? (
          <div>
            <Grid container minWidth={500}>
              <Grid item xs={12}>
                <Paper elevation={1} sx={HomeStyles.paper}>
                  <Typography
                    sx={HomeStyles.header}
                    color={theme.palette.neutral["800"]}
                  >
                    Hello, {name}
                  </Typography>
                  <Typography
                    sx={{ mt: 1 }}
                    color={theme.palette.neutral["400"]}
                  >
                    Welcome to the Next Gen Portal!
                  </Typography>
                  <Typography color={theme.palette.neutral["400"]}>
                    A tool built to manage operation workflow and workload.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </AuthenticatedTemplate>
    </div>
  );
}
