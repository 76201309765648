import { SxProps } from "@mui/material";

const folderIconContainer: SxProps = {
  mt: "240px",
  display: "flex",
  justifyContent: "center",
};

const underIconText: SxProps = {
  textAlign: "center",
  color: "#737373",
  fontWeight: "600",
};

const refreshButton: SxProps = {
  mt: 2,
  bgcolor: "#F5831F",
  color: (theme:any)=>theme.palette.common.white,
  width: "150px",
};
export default { folderIconContainer, underIconText, refreshButton };
