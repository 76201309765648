import React from "react";
import MUIDrawer from "@mui/material/Drawer";
import { drawerWidth } from "../../../../common/theme";

export default function Drawer(props: any) {
  return (
    <MUIDrawer
      {...props}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      {props.children}
    </MUIDrawer>
  );
}
