import { Box, Typography } from "@mui/material";
import QueueTitleStyles from "./queueTitle.style";
import dot from "../../../../assets/dot.svg";
import { useSelector } from "react-redux";
import AppState from "../../../../redux/appStore";

export default function QueueTitle({
  queueName,
  linkedQueueName,
  queueId,
}: {
  queueName: string | undefined;
  linkedQueueName: string | undefined;
  queueId: string | undefined;
}) {
  const queues = useSelector((state: AppState) => state.queueState.queues);

  const queueGroup = queues.find((QG) =>
    QG.queues.some((q) => q.queueId == queueId)
  );

  return (
    <>
      <Box>
        <Typography
          data-auto-test-id="group-name-text"
          sx={QueueTitleStyles.groupName}
        >
          {queueGroup?.queueGroupName}
        </Typography>
        <img src={dot} style={{ margin: "0 10px 2px 10px" }} alt="dot" />
        <Typography
          data-auto-test-id="queue-dot-text"
          sx={QueueTitleStyles.queueName}
        >
          {queueName}
        </Typography>
      </Box>
      <Typography
        data-auto-test-id="queue-main-text"
        sx={QueueTitleStyles.mainTitle}
      >
        {queueName}
      </Typography>
      {linkedQueueName && (
        <Typography
          data-auto-test-id="queue-reported-text"
          sx={QueueTitleStyles.linkedQueueName}
        >
          {`This queue is reported as part of ${linkedQueueName}`}
        </Typography>
      )}
    </>
  );
}
