import { Grid } from "@mui/material";
import { SortDirection } from "../../models/common/sortTableHeader";
import { iconPointer } from "./sortIcon.style";
import upIcon from "../../assets/Up.svg";
import downIcon from "../../assets/Down.svg";

import activeUpIcon from "../../assets/ActiveUp.svg";
import activeDownIcon from "../../assets/ActiveDown.svg";

export default function SortIcons({
  sortField,
  sortDir,
  itemId,
}: {
  sortField: number;
  sortDir: SortDirection;
  itemId: number;
}) {
  return (
    <Grid item flexDirection={"column"} display={"flex"} sx={iconPointer}>
      <img
        data-testid={
          sortField === itemId && sortDir === "asc" ? "active-up" : "up"
        }
        src={sortField === itemId && sortDir === "asc" ? activeUpIcon : upIcon}
        alt="up"
      />

      <img
        data-testid={
          sortField === itemId && sortDir === "desc" ? "active-down" : "down"
        }
        src={
          sortField === itemId && sortDir === "desc" ? activeDownIcon : downIcon
        }
        alt="down"
      />
    </Grid>
  );
}
