import Box from "@mui/material/Box";
import Dialog from "../dialog";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  isChanged,
} from "../../redux/slices/unsavedChangesDataSlice";
import {
  SaveButton,
  actionButton,
  contentContainer,
} from "./unsavedChangesDialog.style";
import AppState from "../../redux/appStore";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../../redux/slices/snackbarSlice";
import { ModalActionType } from "../../models/store/unsavedChangesDataState";

export default function UnsavedChangesDialog() {
  const {
    openModal: isOpen,
    modalConfirmedActionType: modalActionType,
    modalConfirmedActionData: modalActionData,
  } = useSelector((state: AppState) => ({
    openModal: state.unsavedChangesDataState.openModal,
    modalConfirmedActionType:
      state.unsavedChangesDataState.modalConfirmedActionType,
    modalConfirmedActionData:
      state.unsavedChangesDataState.modalConfirmedActionData,
  }));

  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleLeaveWithoutSave = () => {
    if (modalActionType === ModalActionType.navigate) {
      nav(modalActionData);
    } else {
      dispatch(showSnackbar(modalActionData));
    }
    dispatch(closeModal());
    dispatch(isChanged(false));
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <Dialog
        dismissable={false}
        isOpen={isOpen}
        content={
          <Box sx={contentContainer}>
            You didn’t save your changes! If you proceed all your changes will
            be lost.
          </Box>
        }
        actions={
          <>
            <Button
              fullWidth
              sx={SaveButton}
              onClick={() => handleLeaveWithoutSave()}
              autoFocus
              variant="contained"
            >
              Leave without Saving
            </Button>
            <Button
              sx={actionButton}
              fullWidth
              autoFocus
              onClick={() => {
                dispatch(closeModal());
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        }
        handleCloseModal={() => {
          dispatch(closeModal());
        }}
      />
    </Box>
  );
}
