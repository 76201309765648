import { SxProps } from "@mui/material";

const paper: SxProps = {
  height: "176px",
  mt: "60px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
};

const header: SxProps = {
  mt: 5,
  fontSize: "24px",
  fontWeight: "700",
};

export default { paper, header };
