import { SxProps } from "@mui/material";

export const contentContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  minWidth: "350px",
  minHeight: "100px",
};
export const actionButton: SxProps = {
  color: (theme: any) => theme.palette.neutral[900],
  "&:disabled": {
    color: (theme: any) => theme.palette.neutral[500],
  },
  fontWeight: 600,
};

export const SaveButton: SxProps = {
  color: (theme: any) => theme.palette.common.white,
  "&:disabled": {
    color: (theme: any) => theme.palette.common.white,
  },
  fontWeight: 600,
};
