import { useCallback } from "react";
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Badge,
  Grid,
} from "@mui/material";
import Styles from "./field.style";
import FilterLookupItem from "../../../../../../../models/filters/filterLookupItem";
import FilterInputProps from "../../../../../../../models/filters/filterInputProps";
import formatAttribute from "../../../../../../../common/formatAttribute";

const RadioFilter = ({
  title,
  handleChange,
  selectedValue,
  options,
}: FilterInputProps) => {
  const getLabel = useCallback((item: FilterLookupItem) => {
    return (
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"center"}
        data-testid="radio-filter"
      >
        <Typography
          data-auto-test-id={
            formatAttribute(item.name ?? "") + "-filter-item-text"
          }
          mr={1}
          pr={2}
        >
          {item.name}
        </Typography>
        <Badge
          data-auto-test-id={
            formatAttribute(item.name ?? "") + "-filter-item-badge"
          }
          badgeContent={item.ordersCount}
          sx={Styles.badge}
          max={Number.MAX_SAFE_INTEGER}
        />
      </Grid>
    );
  }, []);

  return (
    <Box>
      {title && (
        <Typography
          data-auto-test-id={formatAttribute(title ?? "") + "-filter-text"}
          align="left"
          sx={Styles.title}
        >
          Select {title}
        </Typography>
      )}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedValue || ""}
        onChange={(e, value) => handleChange(value)}
      >
        <Grid container>
          {options?.map((item: FilterLookupItem, index: number) => {
            return (
              <Grid item mr={1} key={item.id}>
                <FormControlLabel
                  sx={{ width: "auto" }}
                  key={item.id}
                  value={item.id}
                  control={
                    <Radio
                      data-auto-test-id={
                        formatAttribute(item.name ?? "") + "-filter-item-radio"
                      }
                    />
                  }
                  label={getLabel(item)}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

export default RadioFilter;
