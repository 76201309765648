import { Route, Routes } from "react-router-dom";
import Login from "../pages/login/login";

function UnauthorizedRoutes() {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
export default UnauthorizedRoutes;
