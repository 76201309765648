import styled from "@emotion/styled";
import { Tab } from "@mui/material";
import { colors } from "./theme";

export default styled((props: { label: string; disabled: boolean }) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  marginTop: "15px",
  fontSize: "18px",
  fontWeight: 400,
  color: colors.neutral["800"],
  "&.Mui-selected": {
    background: colors.primary["100"],
  },
}));
