import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNextOrder } from "../core/services/order/orderService";
import { Links } from "../common/menuItems";
import { QueueFiltersProps } from "../models/filters/queueFiltersProps";
import NextOrderRequest from "../models/order/nextOrderRequest";
import { UserOrderFilterModel } from "../models/order/userOrderFilterModel";
import appConfig from "../settings/appConfig";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../redux/slices/snackbarSlice";
import AppState from "../redux/appStore";
import { setUserOrders } from "../redux/slices/userOrdersSlice";
import UserOrders from "../models/order/userOrders";

const useOrder = ({
  id,
  queueFilters,
  setIsModalOpen,
  setQueueCount,
  isLeadOrAdmin,
  setIsLastOrderPicked,
  setIsErrorModalOpen,
  setQueueDisabled,
}: {
  id: number;
  queueFilters: QueueFiltersProps;
  setIsModalOpen: (value: boolean) => void;
  setQueueCount: (count: number | null) => void;
  isLeadOrAdmin: boolean;
  setIsLastOrderPicked: (value: boolean) => void;
  setIsErrorModalOpen: (value: boolean) => void;
  setQueueDisabled: (value: boolean) => void;
}) => {
  const [orderNos, setOrderNos] = useState<string[] | undefined>();
  const [isLoadingNextOrder, setLoadingNextOrder] = useState<boolean>(false);
  const [nextOrder, setNextOrder] = useState<UserOrders | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { queues, userOrder } = useSelector((state: AppState) => ({
    queues: state.queueState.queues,
    userOrder: state.userOrdersState.userOrders,
  }));

  useEffect(() => {
    if (queues && queues.length > 0) {
      if (userOrder) {
        if (userOrder.queueId == +(id || 0)) {
          setOrderNos(userOrder?.userOrderList?.map((o) => o.orderNo));
        } else {
          setOrderNos([]);
          if (!isLeadOrAdmin) {
            navigate(`../${Links.queue}/${userOrder.queueId}`);
          }
        }
      }
    }
  }, [id, queues, userOrder, navigate, isLeadOrAdmin]);

  // function to get next order in queue
  async function handleGetOrder() {
    setLoadingNextOrder(true);

    const queueId = id;
    if (queueId !== null) {
      let userOrderFilters: UserOrderFilterModel[] =
        queueFilters.filterDependencies?.map<UserOrderFilterModel>((f) => ({
          filterId: f.filterId ?? 0,
          filterValueId: +(f.value ?? 0),
        }));
      let getNextOrderRequest: NextOrderRequest = {
        queueId: queueId.toString(),
        userOrderFilters,
      };

      let nextOrder: UserOrders | null = null;
      try {
        nextOrder = await getNextOrder(getNextOrderRequest);
      } catch (error) {
        dispatch(
          showSnackbar(appConfig.ErrorMessages.getNextOrderErrorMessage)
        );
        setLoadingNextOrder(false);
        return;
      }
      if (nextOrder?.queueDisabled) {
        setQueueDisabled(true);
      }
      if (nextOrder && nextOrder?.userOrderList === null) {
        setIsErrorModalOpen(true);
        setLoadingNextOrder(false);
        return;
      }

      if (nextOrder?.queueId != queueId) {
        navigate(`../${Links.queue}/${nextOrder?.queueId}`, {
          state: nextOrder,
        });
        setLoadingNextOrder(false);
        return;
      }

      if (nextOrder?.remainingOrdersCount === 0) setIsLastOrderPicked(true);
      setQueueCount(nextOrder?.remainingOrdersCount);
      setNextOrder(nextOrder);
      if (
        !nextOrder ||
        (nextOrder.remainingOrdersCount == 0 && nextOrder.userOrderList == null)
      ) {
        setOrderNos(undefined);
        dispatch(setUserOrders(null));
        setIsModalOpen(true);
      } else {
        setOrderNos(nextOrder?.userOrderList?.map((o) => o.orderNo));
        dispatch(setUserOrders(nextOrder));
      }
    }
    setLoadingNextOrder(false);
  }

  return {
    handleGetOrder,
    orderNos,
    isLoadingNextOrder,
    nextOrder,
  };
};

export default useOrder;
