// Styles.ts
import { SxProps } from "@mui/material";

const menuItemContainer: SxProps = {
  backgroundColor: (theme: any) => theme.palette.background.paper,
};

const accordionSummary: SxProps = {
  cursor: "default",
  borderBottom: "2px solid",
  borderColor: (theme: any) => theme.palette?.neutral["200"],
};

const sideBarButton: SxProps = {
  borderBottom: "2px solid",
  paddingLeft: "25px",
  borderColor: (theme: any) => theme.palette?.neutral["200"],
};

const activeSideBarButton: SxProps = {
  backgroundColor: (theme: any) => theme.palette?.primary["100"],
};

const badge = (volumeColor: string): SxProps => ({
  "& .MuiBadge-badge": {
    borderRadius: "25px",
    padding: "12px 4px",
    color: (theme: any) =>
      volumeColor !== theme.palette?.neutral["200"]
        ? theme.palette?.common.white
        : "black",
    backgroundColor: volumeColor,
  },
});

const popoverPaper = {
  backgroundColor: (theme: any) => theme.palette?.neutral["600"],
  color: "white",
  padding: "5px",
};

const popover = {
  pointerEvents: "none",
};

const noMargin: SxProps = { margin: "auto", padding: 0 };

const queueGroupAccordionSummary: SxProps = {
  cursor: "default",
  borderBottom: "2px solid",
  paddingLeft: "25px",
  borderColor: (theme: any) => theme.palette?.neutral["200"],
};

const accordionTitle = {
  marginTop: "24px",
  cursor: "default",
  color: (theme: any) => theme.palette?.neutral["400"],
  fontWeight: "700",
};

export default {
  menuItemContainer,
  accordionSummary,
  noMargin,
  sideBarButton,
  activeSideBarButton,
  badge,
  popover,
  popoverPaper,
  accordionTitle,
  queueGroupAccordionSummary,
};
