import logo from "../../assets/logo.svg";
import squares from "../../assets/Login/squares.svg";
import Box from "@mui/material/Box";
import styles from "./login.style";
import { Grid, Typography } from "@mui/material";
import { SignInButton } from "./components/signInButton";

const Login = () => {
  return (
    <Grid
      container
      sx={styles.LoginPage}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <Grid item xs={6} sx={styles.leftPart}>
        <Grid container justifyContent={"flex-end"}>
          <Grid
            sx={{
              position: "absolute",
            }}
            item
          >
            <Box sx={styles.image}>
              <img src={squares} alt="squares" />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          height={"100%"}
        >
          <Grid item sx={styles.infoContainer}>
            <Box sx={styles.infoContent}>
              <Typography sx={styles.title}>
                Manage Operations Workload!
              </Typography>
              <Typography sx={styles.desc}>
                A tool built for operations to help them manage their workload
                by grouping like orders together that can be assigned out to a
                team of people to work.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sx={styles.rightPart}>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          height="100%"
        >
          <Grid item>
            <Grid
              container
              flexDirection={"column"}
              justifyContent="center"
              alignItems={"center"}
              height="400px"
            >
              <Grid item>
                <Box sx={styles.logo}>
                  <img src={logo} alt="logo" />
                </Box>
              </Grid>
              <Grid item>
                <Box sx={styles.form}>
                  <Typography sx={styles.formTitle}>
                    Login using Ontellus credentials
                  </Typography>
                  <SignInButton />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Login;
