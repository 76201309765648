import { SxProps } from "@mui/material";

const queueName: SxProps = {
  color: "#262626",
  fontSize: "20px",
  fontWeight: "700",
  mt: "24px",
};

const folderIconContainer: SxProps = {
  mt: "240px",
  display: "flex",
  justifyContent: "center",
};

const underIconText: SxProps = {
  textAlign: "center",
  color: "#737373",
  fontWeight: "600",
};

const badge: SxProps = {
  ml: "20px",
};
export default { queueName, folderIconContainer, underIconText, badge };
