import { SxProps } from "@mui/material";

const TableHead: SxProps = {
  color: (theme: any) => theme.palette.neutral["400"],
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.018em",
};

const TableBody: SxProps = {
  color: (theme: any) => theme.palette.neutral["800"],
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px !important",
  lineHeight: "24px",
  letterSpacing: "0.018em",
};

const title: SxProps = {
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "36px",
  color: (theme: any) => theme.palette.neutral["800"],
  marginBottom: "20px",
};
const classes = {
  TableHead,
  TableBody,
  title,
};

export default classes;
