import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isAuthorized } from "../core/services/auth/authService";
import { RolesEnum } from "../models/enums/rolesEnum";
import AppState from "../redux/appStore";

const useRoles = () => {
  const { auth } = useSelector((state: AppState) => state.authState);

  const isAdmin = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Admin),
    [auth]
  );
  const isLead = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Lead),
    [auth]
  );
  const isRegularUser = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Regular),
    [auth]
  );
  const isLeadOrAdmin = useMemo(() => isAdmin || isLead, [isAdmin, isLead]);

  return {
    isAdmin,
    isLead,
    isRegularUser,
    isLeadOrAdmin,
  };
};

export default useRoles;
