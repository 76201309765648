import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import QueueSettingModel from "../../../../models/status/queueSettingModel";
import { useCallback, useEffect } from "react";
import ValidationError from "../../../../models/status/validationError";
import { isChanged } from "../../../../redux/slices/unsavedChangesDataSlice";
import { useDispatch } from "react-redux";
import FilterModel from "../../../../models/filters/filterModel";
import formatAttribute from "../../../../common/formatAttribute";
import { closeIcon, label, selectStyle } from "../queueSetting.style";
import CloseIcon from "@mui/icons-material/Close";

export default function FiltersTabContent({
  queueSetting,
  setQueueSetting,
  validationErrors,
  filters,
  requiredProps,
  setValidationErrors,
}: {
  queueSetting: QueueSettingModel | undefined;
  setQueueSetting: any;
  validationErrors: ValidationError[];
  filters: FilterModel[];
  requiredProps: string[];
  setValidationErrors: any;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (queueSetting?.queueFilterIds.length === 0) {
      addAnother();
    }
  }, [queueSetting?.queueFilterIds]);

  const onFilterChanged = (e: SelectChangeEvent<number>, index: number) => {
    if (queueSetting) {
      queueSetting.queueFilterIds[index] = +e.target.value;
      setQueueSetting({
        ...queueSetting,
        queueFilterIds: [...queueSetting.queueFilterIds],
      });
      dispatch(isChanged(true));

      const queueGroupIdProp = validationErrors.find(
        (p) => p.propName === requiredProps[1]
      );

      if (queueGroupIdProp) queueGroupIdProp.isValid = true;
      setValidationErrors([...validationErrors]);
    }
  };

  const addAnother = useCallback(() => {
    if (queueSetting) {
      queueSetting?.queueFilterIds.push(0);
      setQueueSetting({
        ...queueSetting,
        queueFilterIds: [...queueSetting.queueFilterIds],
      });
    }
  }, [queueSetting]);

  const getAvailableFilters = useCallback(
    (index: number) => {
      return filters.filter(
        (p) =>
          !queueSetting?.queueFilterIds.includes(p.filterId) ||
          queueSetting?.queueFilterIds[index] === p.filterId
      );
    },
    [filters, queueSetting?.queueFilterIds]
  );

  const removeFilter = useCallback(
    (index: number) => {
      if (queueSetting) {
        queueSetting.queueFilterIds = queueSetting.queueFilterIds.filter(
          (item, i) => i !== index
        );

        setQueueSetting({
          ...queueSetting,
          queueFilterIds: [...queueSetting.queueFilterIds],
        });

        dispatch(isChanged(true));
      }
    },
    [queueSetting]
  );

  return (
    <Box>
      {queueSetting?.queueFilterIds.map((filterId, index) => (
        <Box key={`box-${filterId}`}>
          <Box sx={{ marginTop: "30px" }} key={`box-${filterId}`}>
            {index === 0 ? (
              <Typography data-auto-test-id="first-filter" sx={label}>
                First Filter
              </Typography>
            ) : (
              <Typography data-auto-test-id="next-filter" sx={label}>
                Next Filter
              </Typography>
            )}

            <Box display={"flex"} alignItems={"center"}>
              <Select
                sx={selectStyle}
                key={`select-${filterId}`}
                fullWidth
                size="small"
                value={filterId ?? "0"}
                onChange={(e) => onFilterChanged(e, index)}
                data-auto-test-id="select-queue-group-drop-down"
              >
                <MenuItem key="0" disabled value="0">
                  Select
                </MenuItem>
                {getAvailableFilters(index).map((filter) => (
                  <MenuItem
                    key={filter.filterName}
                    value={filter.filterId}
                    data-auto-test-id={
                      formatAttribute(filter.filterName ?? "") + "-menu-item"
                    }
                  >
                    {filter.filterName}
                  </MenuItem>
                ))}
              </Select>
              {(index > 0 || !queueSetting.isSynced) &&
                queueSetting.queueFilterIds[index] !== 0 && (
                  <CloseIcon
                    sx={closeIcon}
                    data-testid={`close-icon-${index}`}
                    onClick={() => removeFilter(index)}
                  />
                )}
            </Box>
          </Box>
          <Typography
            data-auto-test-id="filter-error-text"
            component="span"
            color={"error"}
            display={"block"}
            key={`error-${filterId}`}
          >
            {validationErrors.find((p) => p.propName === requiredProps[1])
              ?.isValid
              ? ""
              : validationErrors.find((p) => p.propName === requiredProps[1])
                  ?.message}
          </Typography>
          {index === queueSetting?.queueFilterIds.length - 1 &&
            queueSetting?.queueFilterIds.length < filters.length &&
            queueSetting?.queueFilterIds[index] !== 0 && (
              <Button
                variant="outlined"
                sx={{ marginTop: "30px" }}
                onClick={addAnother}
                key={`button-${filterId}`}
              >
                Add Another
              </Button>
            )}
        </Box>
      ))}
    </Box>
  );
}
