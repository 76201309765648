import { Box, Card, CardActions, Grid, Typography } from "@mui/material";
import QueueDetailsStyles from "../../../queueDetails.style";
import UserOrder from "../../../../../models/order/userOrder";

export default function SingleOrderCard({
  userOrder,
  ChangeOrderStatus,
}: {
  userOrder: UserOrder;
  ChangeOrderStatus: JSX.Element | null;
}) {
  return (
    <>
      {userOrder && (
        <Typography mt={1} variant="h6" sx={QueueDetailsStyles.userMsg}>
          Your Current Order
        </Typography>
      )}
      <Box width={400}>
        {userOrder && (
          <Card sx={QueueDetailsStyles.card}>
            <Box m={2} p={1}>
              {userOrder && (
                <>
                  <Typography
                    data-auto-test-id="order-no-text"
                    sx={QueueDetailsStyles.userMsg}
                  >
                    Order Number
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={QueueDetailsStyles.currentOrderNo}
                  >
                    <strong data-auto-test-id="order-no">
                      {userOrder.orderNo}
                    </strong>
                  </Typography>
                </>
              )}
            </Box>
            <CardActions>
              <Grid container justifyContent={"flex-end"}>
                <Grid item m={1}>
                  {userOrder && ChangeOrderStatus}
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
      </Box>
    </>
  );
}
