import appConfig from "../../../settings/appConfig";
import { get, post } from "../../http/apiClient";
import FinishOrder from "../../../models/order/finishOrder";
import NextOrderRequest from "../../../models/order/nextOrderRequest";
import PaginationModel from "../../../models/common/paginationModel";
import Order from "../../../models/order/order";
import UserOrders from "../../../models/order/userOrders";

export const getUserOrders = async (): Promise<UserOrders | null> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.UserOrder.Get}`
    );
    let userOrder = response as UserOrders;
    if (
      userOrder?.userOrderList == null ||
      userOrder?.userOrderList.length === 0
    ) {
      return null;
    }
    return userOrder;
  } catch (error: any) {
    throw error;
  }
};

export const getNextOrder = async (
  nextOrderRequest: NextOrderRequest
): Promise<UserOrders | null> => {
  try {
    const nextOrder = await post<NextOrderRequest, UserOrders>(
      `${appConfig.baseUrl}${appConfig.URLS.UserOrder.GetNext}`,
      nextOrderRequest
    );
    if (nextOrder?.userOrderList?.length === 0) {
      return null;
    }

    return nextOrder;
  } catch (error: any) {
    throw error;
  }
};

export const setOrderDone = async (
  userOrderId: number
): Promise<FinishOrder | null> => {
  try {
    const response = await post<{ userOrderId: number }, FinishOrder>(
      `${appConfig.baseUrl}${appConfig.URLS.UserOrder.FinishOrder}`,
      { userOrderId }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getRemainingOrders = async (
  queueId: string,
  pageSize: number,
  page: number
): Promise<PaginationModel<Order> | null> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Order.GetRemaningOrders}/?queueId=${queueId}&page=${page}&pageSize=${pageSize}`
    );
    const result = response as PaginationModel<Order>;
    return result;
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
};
