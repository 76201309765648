import { AuthenticatedTemplate } from "@azure/msal-react";
import appConfig from "../../settings/appConfig";
import "../../css/iframe.css";

export default function Reports() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <div className="container">
          <iframe
            className="responsive-iframe"
            title="Record Retrieval"
            src={appConfig.reportsUrl}
          ></iframe>
        </div>
      </AuthenticatedTemplate>
    </div>
  );
}
