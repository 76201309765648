// Styles.ts
import { SxProps } from "@mui/material";

const remainingOrderCard: SxProps = {
  marginBottom: "10px",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  color: (theme: any) => theme.palette.neutral["500"],
  gap: "7px",
};

const remainingOrderBadge: SxProps = {
  borderRadius: "10px",
  color: (theme: any) => theme.palette.primary["500"],
  backgroundColor: (theme: any) => theme.palette.primary["50"],
  padding: "0px 5px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  marginTop: "15px",
};

const remainingOrderTitle: SxProps = {
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0px",
  margin: "24px 0 0 0",
  color: (theme: any) => theme.palette.neutral["500"],
};

const tableRow: SxProps = {
  "&:last-child td, &:last-child th": { border: 0 },
};

const tableHeader: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "20px",
  color: (theme: any) => theme.palette.common.black,
};

const tableContainer: SxProps = {
  border: "1px solid",
  borderRadius: "8px",
  borderColor: (theme: any) => theme.palette.neutral["200"],
};

const orderNo: SxProps = {
  color: (theme: any) => theme.palette.neutral["500"],
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
};

const progress: SxProps = {
  display: "flex",
};

const pagination: SxProps = {
  borderTop: "1px solid",
  borderTopColor: (theme: any) => theme.palette.neutral["200"],
};

const classes = {
  remainingOrderBadge,
  remainingOrderCard,
  remainingOrderTitle,
  tableContainer,
  tableHeader,
  tableRow,
  orderNo,
  progress,
  pagination,
};

export default classes;
