import { SxProps } from "@mui/material";

const groupName: SxProps = {
  display: "inline",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: (theme: any) => theme.palette.neutral["800"],
};

const queueName: SxProps = {
  display: "inline",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: (theme: any) => theme.palette.neutral["400"],
};

const mainTitle: SxProps = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "36px",
  color: (theme: any) => theme.palette.neutral["800"],
  marginTop: "8px",
};

const linkedQueueName: SxProps = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: (theme: any) => theme.palette.neutral["500"],
};

const classes = {
  groupName,
  queueName,
  mainTitle,
  linkedQueueName,
};

export default classes;
