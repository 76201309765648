import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import {
  tableContainer,
  tableHeader,
  tableRow,
} from "../../../common/styles/tableStyle.style";
import {
  isChanged,
  openConfirmModal,
} from "../../../redux/slices/unsavedChangesDataSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SubTitle } from "../../queuesSetup/queuesSetup.style";
import {
  statusTitle,
  submitButton,
} from "../../queuesSetup/queueSetting/queueSetting.style";
import AppState from "../../../redux/appStore";
import {
  getAllQueues,
  getQueuesByGroupId,
  updateQueuesWithGroup,
} from "../../../core/services/queue/queueService";
import QueuesGroup from "../../../models/queue/queueGroup";
import { setQueues } from "../../../redux/slices/queueSlice";
import { showSnackbarBySeverity } from "../../../redux/slices/snackbarSlice";
import QueuesWithGroupModel from "../../../models/queue/queuesWithGroupModel";
import { useNavigate, useParams } from "react-router-dom";
import ComponentLoader from "../../../common/loader/componentLoader";
import {
  fieldErrorMessage,
  fieldInputTitle,
} from "../../../common/styles/textField.Style";
import appConfig from "../../../settings/appConfig";
import NotFound from "../../../common/notFound/notFound";
import { ModalActionType } from "../../../models/store/unsavedChangesDataState";

export default function SortQueues() {
  const [queuesWithGroupModel, setQueuesWithGroupModel] =
    useState<QueuesWithGroupModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGroupNameValid, setIsGroupNameValid] = useState<boolean>(true);
  const [invalidGroupNameMessage, setInvalidGroupNameMessage] =
    useState<string>("");

  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { isChanged: isSettingChanged } = useSelector((state: AppState) => ({
    isChanged: state.unsavedChangesDataState.isChanged,
  }));

  const { id } = useParams();
  const groupId = id && Number(id) ? +id : 0;

  const dispatch = useDispatch();
  const nav = useNavigate();

  const getAllQueuesByGroupId = useCallback(async () => {
    try {
      setIsLoading(true);
      if (groupId !== 0) {
        const queues = await getQueuesByGroupId(groupId);
        setNotFound(!queues.success);
        if (queues.success) setQueuesWithGroupModel(queues.result);
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  }, [groupId]);

  useEffect(() => {
    getAllQueuesByGroupId();
  }, [getAllQueuesByGroupId]);

  const getAllQueuesCallBack = useCallback(async () => {
    let queues: QueuesGroup[] = [];
    queues = await getAllQueues();
    dispatch(setQueues(queues));
  }, [dispatch]);

  const handleSaveChange = async () => {
    try {
      if (
        !queuesWithGroupModel?.queueGroupName ||
        !queuesWithGroupModel?.queueGroupName.trim()
      ) {
        setIsGroupNameValid(false);
        setInvalidGroupNameMessage(appConfig.Messages.required);
      } else if (queuesWithGroupModel.queueGroupName.length > 30) {
        setIsGroupNameValid(false);
        setInvalidGroupNameMessage(appConfig.Messages.maxThirty);
      } else {
        setSaveLoading(true);
        await updateQueuesWithGroup(queuesWithGroupModel);
        setSaveLoading(false);
        dispatch(isChanged(false));
        dispatch(
          showSnackbarBySeverity({
            message: "queues are updated successfully",
            severity: "success",
          })
        );
        getAllQueuesCallBack();
        nav("/groupSetting");
      }
    } catch {
      setSaveLoading(false);
      dispatch(
        showSnackbarBySeverity({
          message: "Failed to update Queues",
          severity: "error",
        })
      );
    }
  };

  const handleChangeGroupName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (queuesWithGroupModel) {
      setIsGroupNameValid(true);
      queuesWithGroupModel.queueGroupName = e.target.value;
      setQueuesWithGroupModel({ ...queuesWithGroupModel });
      dispatch(isChanged(true));
    }
  };

  const handleBack = () => {
    const modelActionData = "/groupSetting";

    if (isSettingChanged) {
      const modelActionType: ModalActionType = ModalActionType.navigate;

      dispatch(
        openConfirmModal({
          modalConfirmedActionType: modelActionType,
          modalConfirmedActionData: modelActionData,
        })
      );
    } else nav(modelActionData);
  };

  /* istanbul ignore next */
  const handleDrop = (e: any) => {
    if (queuesWithGroupModel?.queues) {
      if (!e.destination) return;

      const sourceIndex = e.source.index;
      const destinationIndex = e.destination.index;

      if (sourceIndex === destinationIndex) return;
      let tempData = Array.from(queuesWithGroupModel.queues);
      let [source_data] = tempData.splice(sourceIndex, 1);
      tempData.splice(destinationIndex, 0, source_data);
      setTimeout(() => {
        if (!isSettingChanged) dispatch(isChanged(true));
      }, 100);

      queuesWithGroupModel.queues = tempData.map((item, index) => {
        item.ordinal = index + 1;
        return item;
      });

      setQueuesWithGroupModel({ ...queuesWithGroupModel });
    }
  };

  let inputInnerTextStyles = {
    fontSize: "16px",
    fontWeight: 600,
  };

  return (
    <>
      {notFound ? (
        <NotFound text="The group not found!"></NotFound>
      ) : (
        <>
          <Grid
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={9} display="flex" alignItems="center">
              <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={handleBack} />

              <Typography sx={statusTitle}>
                Edit {queuesWithGroupModel?.queueGroupName}
              </Typography>
            </Grid>

            <Grid item xs={3} justifyContent={"end"} display={"flex"}>
              <Button
                type="submit"
                variant="contained"
                sx={submitButton}
                data-auto-test-id="submit-queues-button"
                disabled={!isSettingChanged}
                onClick={() => handleSaveChange()}
              >
                {saveLoading ? (
                  <CircularProgress color="inherit" size={"2rem"} />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <label htmlFor="groupName-text">
                <Typography
                  data-auto-test-id="group-name-text"
                  sx={fieldInputTitle}
                >
                  Group Name
                </Typography>
              </label>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{ width: "100%", boxSizing: "border-box" }}
                value={queuesWithGroupModel?.queueGroupName ?? ""}
                onChange={handleChangeGroupName}
                error={!isGroupNameValid}
                id="groupName-text"
                inputProps={{
                  style: inputInnerTextStyles,
                  "data-auto-test-id": "group-name-input",
                }}
                helperText={
                  <Typography
                    data-auto-test-id="group-name-error-text"
                    component="span"
                    sx={fieldErrorMessage}
                  >
                    {!isGroupNameValid && invalidGroupNameMessage}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Typography
            data-auto-test-id="group-setting-title"
            sx={{ ...SubTitle, alignSelf: "center" }}
          >
            Sub-Queues
          </Typography>

          <ComponentLoader isOpen={isLoading}>
            <DragDropContext onDragEnd={handleDrop}>
              <TableContainer sx={tableContainer} component={Paper}>
                <Table
                  data-auto-test-id="queue-group-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeader}>Order</TableCell>
                      <TableCell sx={tableHeader}>Queue Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="queues">
                    {(provider) => (
                      <TableBody
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {queuesWithGroupModel?.queues?.map((row, index) => (
                          <Draggable
                            key={row.queueId}
                            draggableId={row.name}
                            index={index}
                          >
                            {(provider) => (
                              <TableRow
                                key={row.queueId}
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                                data-testid={`row-${index}`}
                                data-auto-test-id={`row-${index}`}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  {...provider.dragHandleProps}
                                  sx={{ width: "15%" }}
                                >
                                  <Typography sx={tableRow}>
                                    <MenuIcon />
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "100%" }}
                                >
                                  <Typography sx={tableRow}>
                                    {row.name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provider.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </TableContainer>
            </DragDropContext>
          </ComponentLoader>
        </>
      )}
    </>
  );
}
