import { getFilterLookupItems } from "./../core/services/filter/filterService";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { getFiltersSkeleton } from "../core/services/filter/filterService";
import FilterLookup from "../models/filters/filterLookup";
import FilterDependency from "../models/filters/filterDependency";
import QueueFilterSkeleton from "../models/filters/queueFilterSkeleton";
import FilterLookupItem from "../models/filters/filterLookupItem";
import Queue from "../models/queue/queue";
import UserOrders from "../models/order/userOrders";

const useQueueFilters = ({
  queue,
  userOrders,
  isRegularUser,
}: {
  queue: Queue | null;
  userOrders: UserOrders | null;
  isRegularUser: boolean;
}) => {
  const [filters, setFilters] = useState<QueueFilterSkeleton[]>([]);
  const [filterDependencies, setFilterDependencies] = useState<
    FilterDependency[]
  >([]);
  const [filterLookupList, setFilterLookupList] = useState<FilterLookup[]>([]);
  const [canGetNextOrder, setCanGetNextOrder] = useState<boolean>(false);
  const [savedSelectedFilterValues, setSavedSelectedFilterValues] =
    useState<any>();
  const [savedUserOrderData, setSavedUserOrderData] = useState<
    FilterLookupItem[]
  >([]);
  const [filteredOrderCount, setFilteredOrderCount] = useState<number | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      queue?.queueId &&
      isRegularUser &&
      !userOrders?.userOrderFiltersData?.isInconsistantData
    ) {
      setCanGetNextOrder(true);
      setFilterLookupList([]);
      if (queue.isSynced && !userOrders) getFilters(queue.queueId);
      else if (queue.isSynced && userOrders) {
        let fValues: any = {};
        userOrders.userOrderFiltersData?.lookupData?.forEach(
          (f: FilterLookupItem) => {
            fValues[f.filterId ?? 0] = f.id?.toString();
          }
        );
        setSavedSelectedFilterValues(fValues);
      }
    }
    //init the filters, when the user navigate to a queue or the userOrder has been done
  }, [queue?.queueId, userOrders]);

  useEffect(() => {
    if (queue?.queueId) {
      setSavedSelectedFilterValues(undefined);
    }
    //empty the previous selected values only if the user navigate to another queue
  }, [queue?.queueId]);

  useEffect(() => {
    if (filterLookupList.length > 0) setSelectedOrDefaultValue();
    //set default value or previous selected value (if any), when the filter data changed[usually a new filter data added]
  }, [filterLookupList]);

  const getFilters = useCallback(
    async (queueId: string) => {
      //get queue filters skeleton from db
      setLoading(true);
      setIsFiltersLoading(true);
      setCanGetNextOrder(false);
      try {
        let qFilters = await getFiltersSkeleton(queueId);
        setFilters(qFilters);
        setCanGetNextOrder(qFilters.length === 0);
        //add first filter to filterValues to get it's values
        if (qFilters.length > 0) {
          let firstFilter = qFilters[0];
          if (firstFilter) {
            let values = [
              {
                value: undefined,
                filterId: firstFilter.filterId,
                queueFilterId: firstFilter.queueFilterId,
              },
            ];
            getLookupList(queue?.queueId ?? "", values, qFilters, []);
            setFilterDependencies(values);
          }
        }
      } catch (err) {
        throw err;
      } finally {
        setIsFiltersLoading(false);
        setLoading(false);
      }
    },
    [queue?.queueId]
  );

  const setSelectedOrDefaultValue = useCallback(() => {
    //set default value or previous selected value (if any), when the filter data changed[usually a new filter data added]
    //set default value
    let currentFilter = filterDependencies[filterDependencies.length - 1];
    if (currentFilter.filterId) {
      let dataObj = filterLookupList[filterLookupList.length - 1];
      if (
        dataObj &&
        dataObj.filterId == currentFilter.filterId &&
        dataObj.lookupList.length > 0
      ) {
        let currentFilterSkeleton = filters.find(
          (o) => o.filterId == currentFilter.filterId
        );
        let userFilterValue =
          filteredOrderCount == 0
            ? undefined
            : currentFilterSkeleton?.filterId && savedSelectedFilterValues
            ? savedSelectedFilterValues[currentFilterSkeleton?.filterId]
            : "";
        if (currentFilterSkeleton) {
          if (currentFilterSkeleton?.hasDefaultValue || userFilterValue) {
            let defaultValue = dataObj.lookupList.find((o) => o.isDefault);
            //set default value if any, or maybe set the previous selected value
            let filterHasValue = dataObj.lookupList.find(
              (fd) => fd.id == userFilterValue
            );
            if (defaultValue || filterHasValue) {
              let value = filterHasValue
                ? userFilterValue
                : defaultValue?.id?.toString();
              if (value)
                addOrUpdateFilterValue(value, currentFilter?.filterId, filters);
            }
          } else {
            setIsFiltersLoading(false);
          }
        }
      }
    }
  }, [filters, filterLookupList, filterDependencies]);

  const getLookupList = useCallback(
    async (
      queueId: string,
      values: FilterDependency[],
      filters: QueueFilterSkeleton[],
      lookupList: FilterLookup[]
    ) => {
      //this function is to get current filter data and populate it in the form
      let selectedValues = [...values];
      if (selectedValues.length > 0) {
        //current filter is the latest filter value
        let currentFilter = selectedValues[selectedValues.length - 1];
        if (currentFilter.filterId) {
          setLoading(true);
          try {
            let lookupItems: FilterLookupItem[] = await getFilterLookupItems(
              queueId,
              selectedValues
            );
            setLoading(false);

            let filterIndex = lookupList.findIndex(
              (f) => f.filterId == currentFilter?.filterId
            );
            //remove children data
            let deletedFilters: QueueFilterSkeleton[] = [];
            if (filterIndex > -1)
              deletedFilters = filters.filter(
                (f) => f.ordinal > filters[filterIndex].ordinal
              );
            let newLookupList: FilterLookup[] = [
              ...lookupList.filter(
                (f) => !deletedFilters.some((d) => d.filterId == f.filterId)
              ),
            ];

            //update filter data
            if (filterIndex > -1) {
              lookupList[filterIndex].lookupList = lookupItems;
              newLookupList = [...newLookupList];
            } else {
              //add new filter data
              newLookupList = [
                ...newLookupList,
                {
                  lookupList: lookupItems,
                  filterId: currentFilter?.filterId ?? 0,
                },
              ];
            }
            setFilterLookupList(newLookupList);
          } catch (err) {
            setLoading(false);
            setIsFiltersLoading(false);
            throw err;
          }
        }
      }
    },
    []
  );

  const getFilterValue = useCallback(
    (filterId: number) => {
      //this function is to extract the filter value
      let currentFilter = filters.find((o) => o.filterId == filterId);
      let selectedValue: string | undefined = "";
      if (currentFilter) {
        selectedValue = filterDependencies.find(
          (fv) => fv.filterId == currentFilter?.filterId
        )?.value;
      }
      return selectedValue;
    },
    [filterDependencies, filters]
  );

  const addOrUpdateFilterValue = useCallback(
    (
      value: string | undefined,
      filterId: number,
      filters: QueueFilterSkeleton[]
    ) => {
      //this function triggered whenever the value of any filter changed
      let filterIndex = filters.findIndex(
        (filter) => filter.filterId == filterId
      );
      let deletedFilters = filters.filter(
        (f) => f.ordinal > filters[filterIndex].ordinal
      );

      let newFilterValues: FilterDependency[] = [
        ...filterDependencies.filter(
          (f) =>
            f.filterId && !deletedFilters.some((d) => d.filterId == f.filterId)
        ),
      ];

      let hasNextFilter = false;
      if (filterIndex > -1) {
        // update value for current filter
        let filterValueIndex = newFilterValues.findIndex(
          (f) => f.filterId == filters[filterIndex].filterId
        );
        newFilterValues[filterValueIndex].value = value;
        newFilterValues = [...newFilterValues];

        let nextFilterIndex = filterIndex + 1;
        let nextFilter = null;
        if (filters.length > nextFilterIndex)
          nextFilter = filters[nextFilterIndex];
        //add next queue filter id
        if (nextFilter) {
          hasNextFilter = true;
          newFilterValues = [
            ...newFilterValues,
            {
              value: undefined,
              filterId: nextFilter?.filterId,
              queueFilterId: nextFilter.queueFilterId,
            },
          ];
        }
      }
      if (hasNextFilter)
        getLookupList(
          queue?.queueId ?? "",
          newFilterValues,
          filters,
          filterLookupList
        );
      else {
        setIsFiltersLoading(false);
      }
      setFilterDependencies(newFilterValues);
      setCanGetNextOrder(
        newFilterValues.every(
          (o) => o.value && filters.some((f) => f.filterId == o.filterId)
        ) /*all filters are selected */
      );
    },
    [filterDependencies, filterLookupList, queue?.queueId]
  );

  return {
    canGetNextOrder,
    setCanGetNextOrder,
    filters,
    setFilters,
    filterDependencies,
    setFilterDependencies,
    filterLookupList,
    setFilterLookupList,
    isLoading,
    setLoading,
    getFilterValue,
    addOrUpdateFilterValue,
    savedSelectedFilterValues,
    setSavedSelectedFilterValues,
    savedUserOrderData,
    setSavedUserOrderData,
    setFilteredOrderCount,
    filteredOrderCount,
    isFiltersLoading,
    getLookupList,
    getFilters,
    setSelectedOrDefaultValue,
  };
};
export default useQueueFilters;
