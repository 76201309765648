import { SxProps } from "@mui/material";
const title: SxProps = {
  fontWeight: "bold",
  marginBottom: "20px",
};

const select: SxProps = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid",
    borderColor: (theme: any) => theme.palette.neutral["700"],
    borderRadius: "8px",
  },
};

const option: SxProps = {
  "&.Mui-selected": {
    color: (theme: any) => theme.palette.primary["500"],
    border: "1px solid",
    borderRadius: "8px",
    borderColor: (theme: any) => theme.palette.primary["100"],
    "& .MuiBadge-badge": {
      backgroundColor: (theme: any) => theme.palette.primary["100"],
      color: (theme: any) => theme.palette.primary["500"],
      marginLeft: "1px",
    },
  },
  "& .MuiBadge-badge": {
    backgroundColor: (theme: any) => theme.palette.neutral["200"],
  },
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
};

const alignCenter: SxProps = {
  display: "flex",
  alignItems: "center",
};

const badge: SxProps = {
  "& .MuiBadge-badge": {
    backgroundColor: (theme: any) => theme.palette.primary["500"],
    color: (theme: any) => theme.palette.common.white,
  },
};

const checkedIcon: SxProps = { marginRight: "2px" };

const classes = {
  title,
  option,
  select,
  alignCenter,
  checkedIcon,
  badge,
};

export default classes;
