import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import OrderDoneStyles from "../../../queueDetails.style";
import { setOrderDone } from "../../../../../core/services/order/orderService";
import FinishOrder from "../../../../../models/order/finishOrder";
import { CircularProgress } from "@mui/material";
import appConfig from "../../../../../settings/appConfig";
import { QueueFiltersProps } from "../../../../../models/filters/queueFiltersProps";
import UseQueueHookResult from "../../../../../models/queue/useQueueHookResult";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../../../redux/slices/snackbarSlice";
import AppState from "../../../../../redux/appStore";
import { removeQueue } from "../../../../../redux/slices/queueSlice";
import { setUserOrders } from "../../../../../redux/slices/userOrdersSlice";

export default function OrderStatusAction({
  userOrderId,
  queueFilters,
  useQueueHookResult,
  setIsModalOpen,
}: {
  userOrderId: number | undefined;
  queueFilters: QueueFiltersProps;
  useQueueHookResult: UseQueueHookResult;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { userOrders } = useSelector((state: AppState) => ({
    userOrders: state.userOrdersState.userOrders,
  }));

  const [doneDisabled, setDoneDisable] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getUserOrdersCallback = useCallback(async (userOrderId: number) => {
    if (userOrderId !== null) {
      try {
        onBeforeOrderDone();
        const response = await setOrderDone(userOrderId);
        setDoneDisable(false);
        if (response) {
          setDoneDisable(true);
          handleAfterOrderDone(response);
        }
      } catch (err) {
        console.error(err);
        setDoneDisable(false);
        dispatch(showSnackbar(appConfig.ErrorMessages.unHandleErrorMessage));
      }
    }
  }, []);

  const handleDoneClick = async () => {
    setDoneDisable(true);
    await getUserOrdersCallback(userOrderId as number);
  };

  const onBeforeOrderDone = () => {
    queueFilters.setSavedUserOrderData([
      ...(userOrders?.userOrderFiltersData?.lookupData ?? []),
    ]);
  };

  const handleAfterOrderDone = (response: FinishOrder) => {
    dispatch(setUserOrders(null));

    queueFilters.setFilteredOrderCount(response?.filteredOrdersCount);
    useQueueHookResult.setQueueCount(response?.remainingOrdersCount);

    if (
      (response?.remainingOrdersCount || 0) === 0 &&
      !useQueueHookResult.queue?.isSynced
    ) {
      dispatch(removeQueue(useQueueHookResult.queue?.queueId));
    }
    setIsModalOpen(true);
  };

  return (
    <Button
      variant="contained"
      sx={OrderDoneStyles.actionButton}
      onClick={handleDoneClick}
      disabled={doneDisabled ? true : false}
      data-auto-test-id="mark-as-done-button"
    >
      {doneDisabled ? <CircularProgress size={28} /> : "Mark as Done"}
    </Button>
  );
}
