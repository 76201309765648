import appConfig from "../../../settings/appConfig";
import User from "../../../models/user/user";
import UserRole from "../../../models/user/userRole";
import { get, post } from "../../http/apiClient";
const apiBase: string = appConfig.baseUrl;

export const registerUser = async (
  azureId: string,
  userName: string,
  email: string
): Promise<User | null> => {
  const userModel: User = {
    userId: 0,
    azureId: azureId,
    userName: userName,
    email: email,
  };

  try {
    const userResponse = await post(`${apiBase}${appConfig.URLS.User.Add}`, {
      azureADUserId: userModel.azureId,
      userName: userModel.userName,
      email: userModel.email,
    });
    const userResult = userResponse as User;
    return userResult;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRoles = async (userId: number): Promise<UserRole[]> => {
  try {
    const userRolesResponse = await get(
      `${apiBase}${appConfig.URLS.UserRole.Get}/${userId}`
    );
    const userRoles = userRolesResponse as UserRole[];
    return userRoles;
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
};
