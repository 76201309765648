// Styles.ts
import { SxProps } from "@mui/material";

const orderFilters: SxProps = {
  color: (theme: any) => theme.palette.primary["500"],
};

const title: SxProps = {
  color: (theme: any) => theme.palette.neutral["800"],
};

const iconsContainer: SxProps = {
  width: "56px",
  height: "56px",
  borderRadius: "32px",
  background: "#FED7AA",
};

const icon: SxProps = {
  color: "#F5831F",
};

const orderNumber: SxProps = {
  color: (theme: any) => theme.palette.neutral["500"],
  fontWeight: "600",
};

const contentContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  minWidth: "350px",
  minHeight: "100px",
};

const greyCard: SxProps = {
  backgroundColor: (theme: any) => theme.palette.neutral["100"],
  marginTop: "25px",
  marginBottom: "5px",
  padding: "6px 6px",
  borderRadius: "7px",
};

const filterText: SxProps = {
  color: (theme: any) => theme.palette.neutral["500"],
};

const filterWord: SxProps = {
  color: (theme: any) => theme.palette.neutral["700"],
  fontWeight: 700,
};

const marks: SxProps = {
  color: (theme: any) => theme.palette.neutral["500"],
  fontWeight: 700,
};

const orderActionsContainer: SxProps = {
  margin: "0 16px 16px 16px",
};

const actionButton: SxProps = {
  color: (theme: any) => theme.palette.common.white,
  "&:disabled": {
    color: (theme: any) => theme.palette.common.white,
  },
};

const classes = {
  orderFilters,
  title,
  iconsContainer,
  icon,
  orderNumber,
  contentContainer,
  greyCard,
  filterText,
  filterWord,
  marks,
  actionButton,
  orderActionsContainer,
};

export default classes;
