import { useMemo, useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import SideBarGroup from "./components/sideBarGroup";
import { Links, menuItems } from "../../../common/menuItems";
import Drawer from "./components/drawer";
import { useIsAuthenticated } from "@azure/msal-react";
import QueueIcon from "@mui/icons-material/Queue";
import { CircularProgress } from "@mui/material";
import UserRole from "../../../models/user/userRole";
import { RolesEnum } from "../../../models/enums/rolesEnum";
import { GroupItem } from "../../../models/sideBar/groupItem";
import appConfig from "../../../settings/appConfig";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../redux/appStore";
import { getQueuesOrdersCount } from "../../../core/services/queue/queueService";
import QueueWithOrdersCountGroup from "../../../models/queue/queueWithOrdersCountGroup";
import { setQueues } from "../../../redux/slices/queueSlice";
import Queue from "../../../models/queue/queue";
import QueueWithOrdersCount from "../../../models/queue/queueWithOrdersCount";
import QueuesGroup from "../../../models/queue/queueGroup";

export default function SideBar({ open }: { open: boolean }) {
  const isAuthenticated = useIsAuthenticated();
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [queueWithOrdersCountGroup, setQueueWithOrdersCountGroup] = useState<
    QueueWithOrdersCountGroup[]
  >([]);

  const { auth, isSidebarLoaderOpen, queues, initialized } = useSelector(
    (state: AppState) => ({
      auth: state.authState.auth,
      isSidebarLoaderOpen: state.loaderState.isSidebarLoaderOpen,
      queues: state.queueState.queues,
      initialized: state.queueState.initialized,
    })
  );
  const dispatch = useDispatch();

  const menuItemsWithQueues = useMemo(() => {
    if (isAuthenticated) {
      let queuesGroup = menuItems.find((o) => o.groupName === "Queues");
      if (queues && queues.length > 0) {
        let queueGroupItems: GroupItem[] = queues?.map<GroupItem>((o) => ({
          queueGroupId: o.queueGroupId,
          queueGroupName: o.queueGroupName,
          roles: [RolesEnum.Lead, RolesEnum.Regular, RolesEnum.Admin],
          isQueues: true,
          subItems: o.queues?.map((q) => ({
            id: q.queueId,
            title: q.name,
            roles: [RolesEnum.Lead, RolesEnum.Regular, RolesEnum.Admin],
            Icon: <QueueIcon />,
            navigateTo: `/${Links.queue}/${q.queueId}`,
            count: q.ordersCount,
            max: !q.isSynced ? undefined : q.maxVolume,
            min: !q.isSynced ? undefined : q.minVolume,
            isSynced: q.isSynced,
            isQueue: true,
          })),
        }));
        let newQueueGroup = {
          groupName: "Queues",
          groupItems: queueGroupItems,
        };
        if (!queuesGroup) menuItems.splice(1, 0, newQueueGroup);
        else {
          queuesGroup.groupItems = queueGroupItems;
        }
      } else {
        if (queuesGroup?.groupItems) {
          queuesGroup.groupItems = [];
        }
      }
    }
    return menuItems;
  }, [queues, isAuthenticated]);

  useEffect(() => {
    setUserRoles(auth?.roles ?? []);
  }, [auth]);

  const updateQueueWithOrdersCountGroup = async () => {
    try {
      console.debug("Start get Queues Orders Count");

      let queueWithOrdersCountGroup: QueueWithOrdersCountGroup[] =
        await getQueuesOrdersCount();
      setQueueWithOrdersCountGroup(queueWithOrdersCountGroup);

      console.debug("End successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const updateQueuesInfo = useCallback(async () => {
    try {
      console.debug("check if queues should update");

      let storedQueueGroups: QueuesGroup[] = JSON.parse(JSON.stringify(queues));

      if (Object.keys(queueWithOrdersCountGroup).length === 0) return;
      let changed = false;
      storedQueueGroups = storedQueueGroups.map((oldGroup) => {
        let group = queueWithOrdersCountGroup.find(
          (p) => p.queueGroupId === oldGroup.queueGroupId
        );

        if (group) {
          let resultQueues = updateQueuesOrder(oldGroup.queues, group.queues);
          if (resultQueues.isChanged) {
            oldGroup.queues = resultQueues.resultQueues;
            changed = true;
          }
        }
        return oldGroup;
      });

      if (changed) {
        console.debug("change QueueStore", storedQueueGroups);
        dispatch(setQueues(storedQueueGroups));
      }
    } catch (err) {
      console.debug("can't update Queues");
      console.error(err);
    }
  }, [queues, dispatch, queueWithOrdersCountGroup]);

  const updateQueuesOrder = (
    oldQueues: Queue[],
    newQueues: QueueWithOrdersCount[]
  ): { isChanged: boolean; resultQueues: Queue[] } => {
    let isChanged = false;
    let resultQueues = oldQueues.map((oldQueue) => {
      let newQueue = newQueues.find((p) => p.queueId === oldQueue.queueId);
      if (newQueue && oldQueue.ordersCount !== newQueue.ordersCount) {
        oldQueue.ordersCount = newQueue.ordersCount;
        isChanged = true;
      } else if (!newQueue) {
        oldQueue.ordersCount = 0;
        isChanged = true;
      }
      return oldQueue;
    });
    return { isChanged: isChanged, resultQueues };
  };

  const interval = useRef<any>();
  const initTheInterval = async () => {
    if (initialized) {
      interval.current = undefined;
      //clear the interval
      console.debug("clear Interval");
      window.clearInterval(interval.current);
      await updateQueueWithOrdersCountGroup();
      console.debug("run: updateQueuesInfo");
      //reinit the interval after updating queues info
      console.debug("reinit the interval after updating queues info");
      interval.current = setInterval(() => {
        updateQueueWithOrdersCountGroup();
      }, appConfig.queuesInfoUpdateInterval);
    }
  };
  useEffect(() => {
    initTheInterval();
    return () => {
      console.debug("clear Interval");
      window.clearInterval(interval.current);
    };
  }, [initialized]);

  useEffect(() => {
    updateQueuesInfo();
  }, [queueWithOrdersCountGroup]);

  return (
    <Drawer variant="permanent" anchor="left" open={open}>
      <Box
        data-auto-test-id="drawer"
        data-testid="sidebar"
        sx={{ overflow: "auto", marginTop: "70px" }}
      >
        {menuItemsWithQueues
          .filter((o) =>
            o.groupItems.length == 0
              ? true
              : o.groupItems.find((r) =>
                  r.roles?.find((e) => userRoles?.some((r) => r.roleId === e))
                )
          )
          .map((group, index) => (
            <SideBarGroup
              userRoles={auth?.roles ?? []}
              key={group.groupName}
              group={group}
            />
          ))}
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "10px",
        }}
      >
        {isSidebarLoaderOpen ? <CircularProgress color="inherit" /> : null}
      </Box>
    </Drawer>
  );
}
