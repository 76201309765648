import React from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import QueueAttributesFormStyles from "./queueAttributesForm.style";
import { useDispatch } from "react-redux";
import QueueSettingModel from "../../../../../../../models/status/queueSettingModel";
import ValidationError from "../../../../../../../models/status/validationError";
import { isChanged } from "../../../../../../../redux/slices/unsavedChangesDataSlice";

const QueueAttributesForm = ({
  queueSetting,
  setQueueSetting,
  validationErrors,
  requiredProps,
  editValidationErrors,
}: {
  queueSetting: QueueSettingModel | undefined;
  setQueueSetting: any;
  validationErrors: ValidationError[];
  requiredProps: string[];
  editValidationErrors: (
    name: string,
    message?: string,
    isValid?: boolean
  ) => void;
}) => {
  const dispatch = useDispatch();

  // #region fields
  const handleTargetRateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (queueSetting) {
      if (queueSetting.queueConfig == null) queueSetting.queueConfig = {};
      queueSetting.queueConfig.individualTargetRate = event.target.value;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));

      editValidationErrors(requiredProps[2], "", true);
    }
  };

  const handleThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (queueSetting) {
      if (queueSetting.queueConfig == null) queueSetting.queueConfig = {};
      queueSetting.queueConfig.thresholdTimeoutInMinutes = event.target.value;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
      editValidationErrors(requiredProps[3], "", true);
    }
  };

  const handleMaxVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (queueSetting) {
      if (queueSetting.queueConfig == null) queueSetting.queueConfig = {};
      queueSetting.queueConfig.maxVolume = event.target.value;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
      editValidationErrors(requiredProps[5], "", true);
    }
  };

  const handleMinVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (queueSetting) {
      if (queueSetting.queueConfig == null) queueSetting.queueConfig = {};

      queueSetting.queueConfig.minVolume = event.target.value;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
      editValidationErrors(requiredProps[4], "", true);
    }
  };
  // #endregion

  let inputInnerTextStyles = {
    fontSize: "16px",
    fontWeight: 600,
  };

  return (
    <Box>
      <Grid container sx={QueueAttributesFormStyles.fromContainer}>
        <Box>
          <label htmlFor="Target-text">
            <Typography
              data-auto-test-id="target-rate-text"
              sx={QueueAttributesFormStyles.inputTitle}
            >
              Target Rate (Order/hr)
            </Typography>
          </label>
          <TextField
            sx={QueueAttributesFormStyles.textField}
            value={queueSetting?.queueConfig?.individualTargetRate ?? ""}
            onChange={handleTargetRateChange}
            error={
              !validationErrors.find((p) => p.propName === requiredProps[2])
                ?.isValid
            }
            id="Target-text"
            inputProps={{
              style: inputInnerTextStyles,
              "data-auto-test-id": "target-rate-input",
            }}
            helperText={
              <Typography
                data-auto-test-id="target-rate-error-text"
                component="span"
                sx={QueueAttributesFormStyles.errorMessage}
              >
                {validationErrors.find((p) => p.propName === requiredProps[2])
                  ?.isValid
                  ? ""
                  : validationErrors.find(
                      (p) => p.propName === requiredProps[2]
                    )?.message}
              </Typography>
            }
          />
        </Box>
        <Box>
          <label htmlFor="Threshold-text">
            <Typography
              data-auto-test-id="threshold-text"
              sx={QueueAttributesFormStyles.inputTitle}
            >
              Threshold (Min)
            </Typography>
          </label>
          <TextField
            sx={QueueAttributesFormStyles.textField}
            value={queueSetting?.queueConfig?.thresholdTimeoutInMinutes}
            onChange={handleThresholdChange}
            error={
              !validationErrors.find((p) => p.propName === requiredProps[3])
                ?.isValid
            }
            id="Threshold-text"
            inputProps={{
              style: inputInnerTextStyles,
              "data-auto-test-id": "threshold-input",
            }}
            helperText={
              <Typography
                data-auto-test-id="threshold-error-text"
                component="span"
                sx={QueueAttributesFormStyles.errorMessage}
              >
                {validationErrors.find((p) => p.propName === requiredProps[3])
                  ?.isValid
                  ? ""
                  : validationErrors.find(
                      (p) => p.propName === requiredProps[3]
                    )?.message}
              </Typography>
            }
          />
        </Box>
        <Box>
          <label htmlFor="min-volume-text">
            <Typography
              data-auto-test-id="min-volume-text"
              sx={QueueAttributesFormStyles.inputTitle}
            >
              Min Volume
            </Typography>
          </label>
          <TextField
            sx={QueueAttributesFormStyles.textField}
            value={queueSetting?.queueConfig?.minVolume}
            onChange={handleMinVolumeChange}
            error={
              !validationErrors.find((p) => p.propName === requiredProps[4])
                ?.isValid
            }
            inputProps={{
              style: inputInnerTextStyles,
              "data-auto-test-id": "min-volume-input",
            }}
            helperText={
              <Typography
                data-auto-test-id="min-volume-error-text"
                component="span"
                sx={QueueAttributesFormStyles.errorMessage}
              >
                {validationErrors.find((p) => p.propName === requiredProps[4])
                  ?.isValid
                  ? ""
                  : validationErrors.find(
                      (p) => p.propName === requiredProps[4]
                    )?.message}
              </Typography>
            }
            id="min-volume-text"
          />
        </Box>
        <Box>
          <label htmlFor="max-volume-text">
            <Typography
              data-auto-test-id="max-volume-text"
              sx={QueueAttributesFormStyles.inputTitle}
            >
              Max Volume
            </Typography>
          </label>
          <TextField
            sx={QueueAttributesFormStyles.textField}
            value={queueSetting?.queueConfig?.maxVolume}
            onChange={handleMaxVolumeChange}
            error={
              !validationErrors.find((p) => p.propName === requiredProps[5])
                ?.isValid
            }
            id="max-volume-text"
            inputProps={{
              style: inputInnerTextStyles,
              "data-auto-test-id": "max-volume-input",
            }}
            helperText={
              <Typography
                data-auto-test-id="max-volume-error-text"
                component="span"
                sx={QueueAttributesFormStyles.errorMessage}
              >
                {validationErrors.find((p) => p.propName === requiredProps[5])
                  ?.isValid
                  ? ""
                  : validationErrors.find(
                      (p) => p.propName === requiredProps[5]
                    )?.message}
              </Typography>
            }
          />
        </Box>
      </Grid>
    </Box>
  );
};
export default QueueAttributesForm;
