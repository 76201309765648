import Box from "@mui/material/Box";
import Dialog from "../../../../../../common/dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import OrderDoneErrorStyles from "./orderDoneError.style";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Grid, Typography } from "@mui/material";

export default function GetNextErrorDialog({
  isErrorModalOpen,
  setIsErrorModalOpen,
}: {
  isErrorModalOpen: boolean;
  setIsErrorModalOpen: any;
}) {
  const refresh = () => {
    handleCloseErrorDialog();
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <Dialog
        closable={false}
        dismissable={false}
        isOpen={isErrorModalOpen}
        content={
          <Box sx={OrderDoneErrorStyles.contentContainer}>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                sx={OrderDoneErrorStyles.iconsContainer}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ReportProblemIcon
                  sx={OrderDoneErrorStyles.icon}
                  fontSize="medium"
                />
              </Grid>
            </Grid>
            <Typography
              mt={1}
              align="center"
              fontWeight={"bold"}
              sx={OrderDoneErrorStyles.title}
            >
              Oops!
            </Typography>
            <Box>
              <DialogContentText align="center" mt={1}>
                Oops! The order has been picked by someone else!
              </DialogContentText>

              <DialogContentText align="center" mt={1}>
                Please refresh.
              </DialogContentText>
            </Box>
          </Box>
        }
        actions={
          <Grid container m={2} sx={OrderDoneErrorStyles.orderActionsContainer}>
            <Grid item xs={12}>
              <Button
                sx={OrderDoneErrorStyles.actionButton}
                fullWidth
                onClick={refresh}
                autoFocus
                variant="contained"
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
        }
        handleCloseModal={handleCloseErrorDialog}
      />
    </Box>
  );
}
