import { Divider } from "@mui/material";
import QueueAttributesForm from "./components/queueAttributesForm/queueAttributesForm";
import QueueConfigLogs from "./components/queueConfigLogs/queueConfigLogs";
import QueueSettingModel from "../../../../../models/status/queueSettingModel";
import ValidationError from "../../../../../models/status/validationError";

function AttributesTabContent({
  queueSetting,
  setQueueSetting,
  validationErrors,
  requiredProps,
  editValidationErrors,
}: {
  queueSetting: QueueSettingModel | undefined;
  setQueueSetting: any;
  validationErrors: ValidationError[];
  requiredProps: string[];
  editValidationErrors: (
    name: string,
    message?: string,
    isValid?: boolean
  ) => void;
}) {
  return (
    <>
      <QueueAttributesForm
        queueSetting={queueSetting}
        setQueueSetting={setQueueSetting}
        validationErrors={validationErrors}
        requiredProps={requiredProps}
        editValidationErrors={editValidationErrors}
      />
      <Divider sx={{ margin: "20px 1px", borderWidth: "1.5px" }} />
      <QueueConfigLogs statusId={queueSetting?.statusId} />
    </>
  );
}

export default AttributesTabContent;
