export const msalConfig = {
  auth: {
    clientId: window._env_.MSAL_AUTH_CLIENT_ID as string,
    resourceId: window._env_.MSAL_AUTH_RESOURCE_ID as string,
    authority: window._env_.MSAL_AUTH_AUTHORITY as string, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window._env_.MSAL_AUTH_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["user.read"],
};

// To get Access token for Workflow Backend
export const tokenRequest = {
  scopes: [`${window._env_.MSAL_AUTH_RESOURCE_ID}/access.workflow`],
};
