import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import RegularUserView from "./components/regularUserView/regularUserView";
import QueueNotFound from "./components/queueNotFound/queueNotFound";
import OrdersEmptyDialog from "./components/regularUserView/components/ordersEmptyDialog";
import queueDetailsStyles from "./queueDetails.style";
import QueueTitle from "./components/queueTitle/queueTitle";
import AdminView from "./components/adminView/adminView";
import useOrder from "../../hooks/useOrder";
import UseOrderHookResult from "../../models/order/useOrderHookResult";
import useQueue from "../../hooks/useQueue";
import useRoles from "../../hooks/useRoles";
import UseRolesHookResult from "../../models/user/useRolesHookResult";
import UseQueueHookResult from "../../models/queue/useQueueHookResult";
import { useSelector } from "react-redux";
import AppState from "../../redux/appStore";

export default function QueueDetails() {
  const { id } = useParams();
  const queueId = parseInt(id ?? "");
  const userOrders = useSelector(
    (state: AppState) => state.userOrdersState.userOrders
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  const useRolesHookResult: UseRolesHookResult = useRoles();
  const useQueueHookResult: UseQueueHookResult = useQueue({
    id: queueId,
    setIsFilterModalOpen,
    isRegularUser: useRolesHookResult.isRegularUser,
  });

  const useOrderHookResult: UseOrderHookResult = useOrder({
    id: queueId,
    setIsModalOpen,
    setIsErrorModalOpen,
    isLeadOrAdmin: useRolesHookResult.isLeadOrAdmin,
    queueFilters: useQueueHookResult.queueFilters,
    setIsLastOrderPicked: useQueueHookResult.setIsLastOrderPicked,
    setQueueCount: useQueueHookResult.setQueueCount,
    setQueueDisabled: useQueueHookResult.setQueueDisabled,
  });

  const handleResetFilter = () => {
    setIsModalOpen(false);
    setIsFilterModalOpen(false);
  };

  const EmptyRemainingOrder = (
    <OrdersEmptyDialog
      handleResetFilter={handleResetFilter}
      isModalFilterOpened={isFilterModalOpen}
    />
  );
  if (
    userOrders == null &&
    useQueueHookResult.queue?.disabled &&
    !isModalOpen
  ) {
    return <QueueNotFound />;
  } else if (useQueueHookResult.isQueueExist) {
    return (
      <Box
        minWidth={500}
        sx={queueDetailsStyles.queueDetailsMainContainer}
        data-testid="queue-details"
      >
        <QueueTitle
          queueName={
            useQueueHookResult.queue?.name
              ? useQueueHookResult.queue?.name + ""
              : ""
          }
          queueId={useQueueHookResult.queue?.queueId}
          linkedQueueName={useQueueHookResult.queue?.linkedQueueName}
        />
        {(useRolesHookResult.isAdmin || useRolesHookResult.isLead) && (
          <AdminView queue={useQueueHookResult.queue} />
        )}
        {useRolesHookResult.isRegularUser && (
          <RegularUserView
            useOrderHookResult={useOrderHookResult}
            useQueueHookResult={useQueueHookResult}
            ModalFilterOpened={EmptyRemainingOrder}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            isErrorModalOpen={isErrorModalOpen}
            setIsErrorModalOpen={setIsErrorModalOpen}
            isRegularUser={useRolesHookResult.isRegularUser}
          />
        )}
      </Box>
    );
  } else if (useQueueHookResult.isQueueExist === false) {
    return <QueueNotFound />;
  } else {
    return <Box />;
  }
}
