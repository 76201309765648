import { useCallback, useState } from "react";
import { Alert, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { importExcel } from "../../core/services/import/importExcelService";
import { getAllQueues } from "../../core/services/queue/queueService";
import appConfig from "../../settings/appConfig";
import QueuesGroup from "../../models/queue/queueGroup";
import { SelectedGroupQueue } from "../../models/queue/selectedGroupQueue";
import { FileUploadModel } from "../../models/queue/fileUpload";
import GroupQueueLinking from "./components/groupQueueLinking";
import UploadFile from "./components/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  hideSidebarLoader,
  showLoader,
  showSidebarLoader,
} from "../../redux/slices/loaderSlice";
import { setQueues } from "../../redux/slices/queueSlice";
import AppState from "../../redux/appStore";

export default function UploadOrders() {
  const [selectedGroupQueue, setSelectedGroupQueue] = useState<
    SelectedGroupQueue | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const auth = useSelector((state: AppState) => state.authState.auth);
  const dispatch = useDispatch();

  const getAllQueuesCallBack = useCallback(async () => {
    dispatch(showSidebarLoader());
    try {
      let queues: QueuesGroup[] = [];
      if (auth?.userId) {
        queues = await getAllQueues();
        dispatch(setQueues(queues));
      }
    } finally {
      dispatch(hideSidebarLoader());
    }
  }, []);

  const handleUpload = useCallback(async (fileModel: FileUploadModel) => {
    try {
      if (
        fileModel.file?.name &&
        fileModel.file.name.length > appConfig.excelFileNameLength
      ) {
        setErrorMessage(
          `The file name not allowed to be more than ${appConfig.excelFileNameLength} characters`
        );
        fileModel.error(
          `The file name not allowed to be more than ${appConfig.excelFileNameLength} characters`
        );
      } else {
        setErrorMessage("");
        const response = await importExcel({
          file: fileModel.file,
          load: fileModel.load,
          progress: fileModel.progress,
          abort: fileModel.abort,
          queueId: fileModel.queueId,
        });

        if (response && response.success === true) {
          await getAllQueuesCallBack();
        } else {
          setErrorMessage("An error occurred while importing orders.");
          fileModel.error("An error occurred while importing orders.");
        }
        return { abort: response.abort };
      }
    } catch (err) {
      setErrorMessage("An error occurred while importing orders.");
      fileModel.error("An error occurred while importing orders.");
      return { abort: () => fileModel.abort() };
    }
  }, []);

  const GroupQueueChanged = (queueGroup: SelectedGroupQueue) => {
    setSelectedGroupQueue(queueGroup);
  };

  return (
    <Card sx={{ minWidth: 275, marginTop: 2 }}>
      <CardHeader
        data-auto-test-id="import-orders-text"
        title="Import Orders"
        titleTypographyProps={{ variant: "h5", fontWeight: "bold" }}
      />
      <Divider />
      <GroupQueueLinking onGroupQueueChanged={GroupQueueChanged} />
      <CardContent>
        <UploadFile
          handleUpload={handleUpload}
          handleUploaded={() => dispatch(showLoader())}
          handleProcessed={() => dispatch(hideLoader())}
          selectedGroupQueue={selectedGroupQueue}
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </CardContent>
    </Card>
  );
}
