import { useEffect, useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Links } from "../../../common/menuItems";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../../settings/authConfig";
import {
  acquireTokenSilent,
  parseJwt,
  persistAuthData,
} from "../../../core/services/auth/authService";
import {
  getRoles,
  registerUser,
} from "../../../core/services/user/userService";
import LoginStyles from "../login.style";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "../../../redux/slices/authSlice";
import { hideLoader, showLoader } from "../../../redux/slices/loaderSlice";

export const SignInButton = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleLogin() {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (instance.getAllAccounts()[0]) {
      handleRegisterUser(instance, navigate);
    }
  }, [instance, navigate]);

  const handleRegisterUser = useCallback(
    async (instance: IPublicClientApplication, navigate: NavigateFunction) => {
      dispatch(showLoader());
      try {
        const accessToken = await acquireTokenSilent(instance);
        const authData = await parseJwt(accessToken as string);
        persistAuthData(authData);

        dispatch(
          setAuthInfo({
            userId: 0,
            azureId: authData.azureId,
            userName: authData.userName,
            email: authData.email,
            token: authData.token,
            roles: authData.roles,
          })
        );

        const user = await registerUser(
          authData.azureId,
          authData.userName,
          authData.email
        );

        if (user !== null) {
          const userRoles = await getRoles(user?.userId);
          dispatch(
            setAuthInfo({
              userId: user.userId,
              azureId: authData.azureId,
              userName: authData.userName,
              email: authData.email,
              token: accessToken,
              roles: userRoles,
            })
          );
        }
        dispatch(hideLoader());
        navigate(Links.home);
      } finally {
        dispatch(hideLoader());
      }
    },
    []
  );

  return (
    <Button
      data-auto-test-id="login-button"
      variant="contained"
      sx={LoginStyles.loginBtn}
      onClick={() => handleLogin()}
    >
      Login
    </Button>
  );
};
