import UserRole from "./userRole";

// @types.todo.ts
export interface IAuthData {
  userId: number;
  azureId: string;
  userName: string;
  email: string;
  token: string;
  roles: UserRole[];
}

export const defaultAuthData: IAuthData = {
  userId: 0,
  azureId: "",
  userName: "",
  email: "",
  token: "",
  roles: [],
};
