import { ChangeEvent, useMemo } from "react";
import { Divider, Typography } from "@mui/material";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import appConfig from "../../../settings/appConfig";
import { SelectedGroupQueue } from "../../../models/queue/selectedGroupQueue";
import { FileUploadModel } from "../../../models/queue/fileUpload";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export default function UploadFile({
  handleUpload,
  handleUploaded,
  handleProcessed,
  selectedGroupQueue,
}: {
  handleUpload: (file: FileUploadModel) => void;
  handleUploaded: () => void;
  handleProcessed: () => void;
  selectedGroupQueue: SelectedGroupQueue | undefined;
}) {
  const onUpload = (
    fieldName: string,
    file: any,
    metadata: any,
    load: any,
    error: any,
    progress: any,
    abort: any
  ) => {
    handleUpload({
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      queueId: +(selectedGroupQueue?.queue?.queueId ?? 0),
    });
  };

  const onFileUploaded = (file: any, progress: number) => {
    if (progress === 1) {
      handleUploaded();
    }
  };
  const OnFileProcessed = () => {
    handleProcessed();
  };
  const OnError = (error: any) => {
    handleProcessed();
  };

  const isGroupQueueSelected: boolean = useMemo(
    () =>
      selectedGroupQueue?.group?.queueGroupId &&
      selectedGroupQueue?.queue?.queueId
        ? true
        : false,
    [selectedGroupQueue]
  );

  // #region Testing Upload Files Needed Code
  // IMPORTANT: The following code are used for unit tests only and should not be included in production code
  // They are meant to simulate the behavior of uplaoding a file.
  const isDevelopment = process.env.NODE_ENV !== "production";

  const handleTestingFileChange = isDevelopment
    ? (event: ChangeEvent<HTMLInputElement>) => {
        const file = (event.target.files as FileList)[0];
        onUpload("", file, null, null, (str: string) => {}, null, null);
        onFileUploaded(file, 1);
      }
    : () => {};

  const testingUploadFileElement = isDevelopment ? (
    <input
      type="file"
      data-testid="testing-file-upload-input"
      disabled={!isGroupQueueSelected}
      onChange={handleTestingFileChange}
      hidden
    ></input>
  ) : null;
  // #endregion

  return (
    <>
      <Typography marginBottom={2} marginTop={2} variant="inherit">
        <span data-auto-test-id="upload-sheet-text">
          Please upload a single excel sheet to create a new queue of orders.
        </span>
      </Typography>
      <Divider />
      <Typography
        marginBottom={2}
        marginTop={2}
        variant="inherit"
        fontWeight="bold"
        data-auto-test-id="upload-from-device"
      >
        Upload from Device
      </Typography>
      <div className="App">
        {testingUploadFileElement}
        <FilePond
          disabled={!isGroupQueueSelected}
          server={{
            process: onUpload,
            revert: () => {},
          }}
          data-auto-test-id="file-upload"
          onprocessfileprogress={onFileUploaded}
          onerror={OnError}
          onprocessfiles={OnFileProcessed}
          allowFileSizeValidation={true}
          maxFileSize={appConfig.importExcel.maxExcelFileSizeInMB}
          labelMaxFileSizeExceeded="File is too large."
          labelFileProcessingError="An error occurred while uploading the file."
          allowFileTypeValidation={true}
          acceptedFileTypes={appConfig.importExcel.acceptedFileTypes}
          labelFileTypeNotAllowed="Wrong format! only excel is accepted."
          allowMultiple={false}
          labelTapToUndo=""
          labelTapToCancel=""
          maxFiles={1}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
      </div>
    </>
  );
}
