// Styles.ts
import { SxProps } from "@mui/material";

const inputTitle: SxProps = {
  marginTop: "23px",
  marginBottom: "10px",
  fontWeight: 700,
  fontSize: "14px",
  color: (theme: any) => theme.palette.neutral["500"],
};

const textField: SxProps = {
  width: "240px",
};

const fromContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
};

const errorMessage: SxProps = {
  display: "block",
  fontSize: "13px",
  width: "240px",
  marginLeft: "3px",
};

const alertWidth: SxProps = {
  width: "100%",
};

const classes = {
  inputTitle,
  textField,
  fromContainer,
  errorMessage,
  alertWidth,
};

export default classes;
