import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Typography,
  Pagination,
  Box,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getQueueConfigLogs } from "../../../../../../../core/services/status/statusService";
import ComponentLoader from "../../../../../../../common/loader/componentLoader";
import PaginationModel from "../../../../../../../models/common/paginationModel";
import QueueConfigLogModel from "../../../../../../../models/queue/queueConfigLogModel";
import QueueConfigLogsRequest from "../../../../../../../models/queue/queueConfigLogsRequest";
import appConfig from "../../../../../../../settings/appConfig";

import styles from "./queueConfigLogs.style";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../../../../redux/slices/snackbarSlice";

export default function QueueConfigLogs({
  statusId,
}: {
  statusId: number | undefined;
}) {
  const [queueConfigLogsModel, setQueueConfigLogModel] =
    useState<PaginationModel<QueueConfigLogModel> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageIdx, setPageIdx] = useState<number>(1);
  const dispatch = useDispatch();

  const getQueueConfigLog = useCallback(
    async (newPage: number) => {
      try {
        if (statusId) {
          setIsLoading(true);

          const queueConfigLogsRequest: QueueConfigLogsRequest = {
            page: newPage - 1,
            pageSize: appConfig.pageCount as number,
            statusId: statusId,
          };
          let queueConfigLogs = await getQueueConfigLogs(
            queueConfigLogsRequest
          );

          setQueueConfigLogModel(queueConfigLogs);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    [statusId]
  );

  const totalNumberOfPages = useMemo(() => {
    try {
      const totalRows = queueConfigLogsModel?.totalCount ?? 0;
      const x =
        totalRows % appConfig.pageCount > 0
          ? Math.floor(totalRows / appConfig.pageCount) + 1
          : Math.floor(totalRows / appConfig.pageCount);

      return x;
    } catch {
      dispatch(showSnackbar(appConfig.ErrorMessages.unHandleErrorMessage));
    }
  }, [queueConfigLogsModel?.totalCount]);

  useEffect(() => {
    setPageIdx(1);
    setQueueConfigLogModel(null);
    getQueueConfigLog(1);
  }, [statusId, getQueueConfigLog]);

  async function handleChangePage(event: unknown, newPage: number) {
    setPageIdx(newPage);
    await getQueueConfigLog(newPage);
  }

  const toUSDate = (date: Date) => {
    const usDate = new Date(date);
    return usDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <>
      <Box>
        <Typography data-auto-test-id="data-logs-title" sx={styles.title}>
          Data Logs
        </Typography>
      </Box>
      <ComponentLoader isOpen={isLoading}>
        <TableContainer component={Paper}>
          <Table data-auto-test-id="data-logs-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles.TableHead}>Modification Date</TableCell>
                <TableCell sx={styles.TableHead}>Modified By</TableCell>
                <TableCell sx={styles.TableHead}>Target (Order/Hr)</TableCell>
                <TableCell sx={styles.TableHead}>Threshold (Min)</TableCell>
                <TableCell sx={styles.TableHead}>Min volume</TableCell>
                <TableCell sx={styles.TableHead}>Max volume</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queueConfigLogsModel?.data.map((row) => (
                <TableRow key={row.queueConfigLogId}>
                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {toUSDate(row.createdAt)}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {row.createdByUserName}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {row.individualTargetRate}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {row.thresholdTimeoutInMinutes}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {row.minVolume}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={styles.TableBody}>
                      {row.maxVolume}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="end" sx={{ margin: "3px" }}>
            <Pagination
              data-auto-test-id="data-logs-table-pagination"
              disabled={isLoading}
              count={totalNumberOfPages}
              hideNextButton
              hidePrevButton
              showFirstButton
              showLastButton
              page={pageIdx}
              shape={"rounded"}
              onChange={handleChangePage}
            />
          </Box>
        </TableContainer>
      </ComponentLoader>
    </>
  );
}
