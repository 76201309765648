import { SxProps } from "@mui/material";

export const fieldInputTitle: SxProps = {
  marginTop: "23px",
  marginBottom: "10px",
  fontWeight: 700,
  fontSize: "14px",
  color: (theme: any) => theme.palette.neutral["500"],
};

export const fieldErrorMessage: SxProps = {
  display: "block",
  fontSize: "13px",
  marginLeft: "3px",
};
