import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQueueGroups,
  updateQueueGroups,
} from "../../core/services/queueGroups/queueGroupsService";
import QueueGroupModel from "../../models/queue/queueGroupModel";
import ComponentLoader from "../../common/loader/componentLoader";
import MenuIcon from "@mui/icons-material/Menu";
import {
  editColor,
  tableContainer,
  tableHeader,
  tableRow,
} from "../../common/styles/tableStyle.style";
import {
  isChanged,
  openConfirmModal,
} from "../../redux/slices/unsavedChangesDataSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Title } from "../queuesSetup/queuesSetup.style";
import { submitButton } from "../queuesSetup/queueSetting/queueSetting.style";
import AppState from "../../redux/appStore";
import { getAllQueues } from "../../core/services/queue/queueService";
import QueuesGroup from "../../models/queue/queueGroup";
import { setQueues } from "../../redux/slices/queueSlice";
import { showSnackbarBySeverity } from "../../redux/slices/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { ModalActionType } from "../../models/store/unsavedChangesDataState";
import { Links } from "../../common/menuItems";

export default function GroupsSetting() {
  const [queueGroups, setQueueGroups] = useState<QueueGroupModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const { isChanged: isSettingChanged } = useSelector((state: AppState) => ({
    isChanged: state.unsavedChangesDataState.isChanged,
  }));

  const dispatch = useDispatch();
  const nav = useNavigate();
  const getAllQueueGroups = useCallback(async () => {
    try {
      setIsLoading(true);
      const groups = await getQueueGroups();
      groups.sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
      setQueueGroups([...groups]);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllQueueGroups();
  }, [getAllQueueGroups]);

  const getAllQueuesCallBack = useCallback(async () => {
    let queues: QueuesGroup[] = [];
    queues = await getAllQueues();
    dispatch(setQueues(queues));
  }, [dispatch]);

  const handleSaveChange = async () => {
    try {
      setSaveLoading(true);
      await updateQueueGroups(queueGroups);
      setSaveLoading(false);
      dispatch(isChanged(false));
      dispatch(
        showSnackbarBySeverity({
          message: "Groups Settings are updated successfully",
          severity: "success",
        })
      );
      getAllQueuesCallBack();
    } catch {
      setSaveLoading(false);
      dispatch(
        showSnackbarBySeverity({
          message: "Failed to update Group Settings",
          severity: "error",
        })
      );
    }
  };

  /* istanbul ignore next */
  const handleDrop = (e: any) => {
    if (!e.destination) return;

    const sourceIndex = e.source.index;
    const destinationIndex = e.destination.index;

    if (sourceIndex === destinationIndex) return;

    if (
      sourceIndex < 0 ||
      sourceIndex >= queueGroups.length ||
      destinationIndex < 0 ||
      destinationIndex > queueGroups.length
    ) {
      console.error("Invalid source or destination index");
      return;
    }

    let tempData = Array.from(queueGroups);
    let [source_data] = tempData.splice(sourceIndex, 1);
    tempData.splice(destinationIndex, 0, source_data);
    setTimeout(() => {
      if (!isSettingChanged) dispatch(isChanged(true));
    }, 100);

    setQueueGroups([...tempData]);
  };

  const handleEditClick = (id: number) => {
    const modelActionData = `/${Links.sortQueues}/${id}`;

    if (isSettingChanged) {
      const modelActionType: ModalActionType = ModalActionType.navigate;
      dispatch(
        openConfirmModal({
          modalConfirmedActionType: modelActionType,
          modalConfirmedActionData: modelActionData,
        })
      );
    } else nav(modelActionData);
  };
  return (
    <>
      <Box display={"flex"} justifyContent="space-between" alignItems="center">
        <Typography
          data-auto-test-id="group-setting-title"
          sx={{ ...Title, alignSelf: "center" }}
        >
          Groups Setup
        </Typography>

        <Button
          type="submit"
          variant="contained"
          sx={{ ...submitButton, alignSelf: "start" }}
          data-auto-test-id="submit-button"
          disabled={!isSettingChanged}
          onClick={handleSaveChange}
        >
          {saveLoading ? (
            <CircularProgress color="inherit" size={"2rem"} />
          ) : (
            "Save Changes"
          )}
        </Button>
      </Box>

      <ComponentLoader isOpen={isLoading}>
        <DragDropContext onDragEnd={handleDrop}>
          <TableContainer sx={tableContainer} component={Paper}>
            <Table
              data-auto-test-id="queue-group-table"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeader}>Order</TableCell>
                  <TableCell sx={tableHeader}>Edit</TableCell>
                  <TableCell sx={tableHeader}>Group Name</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="body">
                {(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {queueGroups?.map((row, index) => (
                      <Draggable
                        key={row.queueGroupId}
                        draggableId={row.queueGroupName}
                        index={index}
                      >
                        {(provider) => (
                          <TableRow
                            key={row.queueGroupId}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            data-testid={`row-${index}`}
                            data-auto-test-id={`row-${index}`}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              {...provider.dragHandleProps}
                              sx={{ width: "15%" }}
                            >
                              <Typography sx={tableRow}>
                                <MenuIcon />
                              </Typography>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "15%" }}
                            >
                              <Button
                                sx={editColor}
                                onClick={() =>
                                  handleEditClick(row.queueGroupId)
                                }
                              >
                                Edit
                              </Button>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "70%" }}
                            >
                              <Typography sx={tableRow}>
                                {row.queueGroupName}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </ComponentLoader>
    </>
  );
}
