import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserOrdersState } from "../../models/store/userOrdersState";
import UserOrders from "../../models/order/userOrders";

const initialState: UserOrdersState = {
  userOrders: null,
};

const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState,
  reducers: {
    setUserOrders: (state, action: PayloadAction<UserOrders | null>) => {
      state.userOrders = action.payload;
    },
  },
});

export const { setUserOrders } = userOrdersSlice.actions;

export default userOrdersSlice.reducer;
