import { useCallback, useEffect, useState } from "react";
import { getRemainingOrders } from "../../../../../core/services/order/orderService";
import Order from "../../../../../models/order/order";
import appConfig from "../../../../../settings/appConfig";
import RemainingOrders from "./components/remainingOrders";
import { Box, Typography } from "@mui/material";
import Styles from "./remainingOrdersPanel.style";
import { useSelector } from "react-redux";
import AppState from "../../../../../redux/appStore";

export default function RemainingOrdersPanel({
  queueId,
}: {
  queueId: string | undefined;
}) {
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(appConfig.pageCount as number);
  const [page, setPage] = useState(0);
  const [remainingOrders, setRemainingOrders] = useState<Order[]>([]);
  const [isRemainingOrdersLoading, setIsRemainingOrdersLoading] =
    useState<boolean>(false);

  const queuesInitialized = useSelector(
    (state: AppState) => state.queueState.initialized
  );

  useEffect(() => {
    if (queuesInitialized) {
      setRemainingOrders([]);
      fetchRemainingOrders(queueId as string);
    }
  }, [queueId, queuesInitialized]);

  useEffect(() => {
    fetchRemainingOrders(queueId as string);
  }, [rowsPerPage]);

  const getNewRemainingOrders = async (pageIndex: number) => {
    const remainingOrders = await getRemainingOrders(
      queueId as string,
      rowsPerPage,
      pageIndex //since server expects zero based page number
    );

    if (remainingOrders && remainingOrders.data.length > 0) {
      setRemainingOrders(remainingOrders.data);
    } else setRemainingOrders([]);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setIsRemainingOrdersLoading(true);
    if (queueId !== null) {
      setPage(newPage);
      await getNewRemainingOrders(newPage);
    }
    setIsRemainingOrdersLoading(false);
  };

  const handleChangeRowsPerPage = async (newRowPage: number) => {
    setTotalCount(0);
    setRowsPerPage(newRowPage);
  };

  const handleGetRemainingOrders = useCallback(
    async (queueId: string, pageIdx: number) => {
      setIsRemainingOrdersLoading(true);

      if (queueId !== null) {
        try {
          const remainingOrders = await getRemainingOrders(
            queueId,
            rowsPerPage,
            pageIdx
          );
          if (remainingOrders?.data) {
            setRemainingOrders(remainingOrders.data);
            setTotalCount(remainingOrders.totalCount);
          }
        } finally {
          setIsRemainingOrdersLoading(false);
        }
      }
      setIsRemainingOrdersLoading(false);
    },
    [rowsPerPage]
  );

  const fetchRemainingOrders = useCallback(
    async (queueId: string) => {
      setTotalCount(0);
      let pageIdx = 0;
      setPage(0);
      await handleGetRemainingOrders(queueId, pageIdx);
    },
    [handleGetRemainingOrders]
  );

  const RemainingOrdersTable = (
    <RemainingOrders
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      totalRows={totalCount}
      rowsPerPage={rowsPerPage}
      pageIdx={page}
      remainingOrders={remainingOrders}
      isLoading={isRemainingOrdersLoading}
    />
  );

  return (
    <Box>
      <Box sx={Styles.remainingOrderCard}>
        <Typography
          data-auto-test-id="remaining-order-page-text"
          sx={Styles.remainingOrderTitle}
        >
          Remaining Orders
        </Typography>
        <Typography sx={Styles.remainingOrderBadge}>
          {totalCount?.toLocaleString("en-US") || 0}
        </Typography>
      </Box>
      {RemainingOrdersTable}
    </Box>
  );
}
