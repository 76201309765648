const appConfig: any = {
  baseUrl: window._env_.API_URL as string,
  excelFileNameLength: 80,
  tokenKey: "token",
  authKey: "auth",
  pageCount: 10,
  queueSetupPageCount: 20,
  queuesInfoUpdateInterval: window._env_.QUEUES_INFO_UPDATE_INTERVAL,
  URLS: {
    ImportExcel: {
      importorders: "importExcel",
    },
    Status: {
      GetAll: "statuses",
      GetQueueSetting: "statuses/getstatusdetails",
      UpdateQueueSetting: "statuses/updatestatusdetails",
      GetQueueConfigLogs: "statuses/getqueueconfiglogs",
    },
    Filter: {
      GetAll: "filters",
    },
    Queue: {
      GetAll: "queues",
      Get: "queues/getbyid",
      GetQueuesOrdersCount: "queues/getqueuesorderscount",
      UpdateQueueConfigurations: "queues/updatequeueconfigurations",
      GetQueueConfigurations: "queues/getqueueconfigurations",
      GetQueueFilters: "queues/getqueuefilters",
      GetLookupdata: "queues/getfilterlookupdata",
      GetQueuesByGroupId: "queues/getqueuesbygroupId",
      UpdateQueuesWithGroup: "queues/updatequeueswithgroup",
    },
    UserOrder: {
      Get: "orders/getmycurrentOrder",
      GetNext: "orders/getnextorders",
      FinishOrder: "orders/finishorder",
    },
    Order: {
      GetRemaningOrders: "orders/getallremainingorders",
    },
    User: {
      Add: "users",
      Get: "users",
    },
    UserRole: {
      Get: "userroles",
    },
    QueueGroups: {
      GetAll: "queuegroups",
      UpdateAll: "queuegroups",
    },
  },
  importExcel: {
    maxExcelFileSizeInMB: window._env_.MAX_EXCEL_FILE_SIZE_IN_MB as string,
    acceptedFileTypes: (window._env_.ACCEPTED_FILE_MIME_TYPES as string)?.split(
      ","
    ),
  },
  reportsUrl: window._env_.REPORTS_URI as string,
  temporaryQueuesGroupId: 1,
  queuesGroupName: "Queues",
  maxSideBarCharLength: 25,
  Messages: {
    required: "Required",
    decimalOnly: "Invalid format. Enter a whole number.",
    maxFive: "Maximum 5 digits",
    maxTwo: "Maximum 2 digits",
    maxMinutes: "Maximum 60 minutes",
    minLessThanMax: "Min should be less than Max",
    maxThirty: "Maximum 30 character",
  },
  ErrorMessages: {
    getNextOrderErrorMessage: "Something went wrong! while getting next order",
    unHandleErrorMessage: "Something went wrong! please try reloading the page",
  },
};
export default appConfig;
