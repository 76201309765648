import * as React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Box } from "@mui/material";
import { Links } from "../../../common/menuItems";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/system";
import { SignOutButton } from "./components/signOutButton";
import Menu from "@mui/material/Menu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import dropIcon from "../../../assets/expand.svg";
import logo from "../../../assets/logo.svg";
import { useMemo } from "react";
import NavBarStyles from "./navBar.style";

export const NavBar = () => {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const name = useMemo(() => accounts[0]?.name, [accounts]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleNameClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropClose = () => {
    setAnchorEl(null);
  };

  const logoClickHandler = () => {
    navigate(Links.home);
  };

  return (
    <>
      <AppBar position="fixed" elevation={1} sx={NavBarStyles.nav}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={NavBarStyles.logo} onClick={logoClickHandler}>
              <img src={logo} alt="Worfklow logo" />
            </Box>
          </Box>
          {isAuthenticated && (
            <Box
              data-testid="user-name"
              data-auto-test-id="user-name"
              sx={{
                cursor: "pointer",
              }}
              onClick={handleNameClick}
            >
              <Typography color={theme.palette.neutral["400"]}>
                Hello,{" "}
                <Typography
                  sx={{ mr: "3px" }}
                  component="span"
                  color={theme.palette?.primary["400"]}
                >
                  {name}
                </Typography>
                <img src={dropIcon} alt="Drop icon" />
              </Typography>
            </Box>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleDropClose}
            sx={NavBarStyles.menu}
          >
            <SignOutButton dropCloseHandler={handleDropClose} />
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
