import { GroupItem } from "../../../../../models/sideBar/groupItem";
import SideBarItem from "./components/components/components/sideBarItem";
import UserRole from "../../../../../models/user/userRole";
import { Box } from "@mui/material";
import Styles from "../../sideBar.style";
import SideBarQueueGroupList from "./components/sideBarQueueGroupList";
import { useMemo } from "react";

export default function SideBarGroupItems({
  isGroupLevelQueue,
  items,
  userRoles,
}: {
  isGroupLevelQueue: boolean;
  items: GroupItem[];
  userRoles: UserRole[];
}) {
  const authorizedItems = useMemo(
    () =>
      items.filter((o) =>
        o.roles?.find((r) => userRoles?.some((w) => w.roleId === r))
      ),
    [items, userRoles]
  );

  const nonQueuesItems = useMemo(
    () => authorizedItems.filter((o) => !o.isQueues),
    [authorizedItems]
  );

  const queuesGroups = useMemo(
    () => authorizedItems.filter((o) => o.isQueues),
    [authorizedItems]
  );

  return (
    <Box
      sx={isGroupLevelQueue ? Styles.menuItemContainer : {}}
      data-testid="sidebar-group-items"
    >
      {nonQueuesItems.map((item: GroupItem, index: number) => (
        <SideBarItem item={item} key={item.title ?? "" + index} />
      ))}
      <SideBarQueueGroupList Groups={queuesGroups} userRoles={userRoles} />
    </Box>
  );
}
