import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogModel from "../models/common/dialogModel";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ResponsiveDialog({
  content,
  actions,
  title,
  isOpen,
  handleCloseModal,
  dismissable,
  closable,
}: DialogModel) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onClose = (event: any, reason: string) => {
    if (!dismissable) if (reason && reason == "backdropClick") return;
    handleCloseModal();
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
          {closable !== false && (
            <IconButton
              aria-label="close"
              onClick={(event) => onClose(event, "closed by dismiss button")}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
}
