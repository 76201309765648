import { ReactNode } from "react";
import { Grid, CircularProgress } from "@mui/material";
import Styles from "./styles/loader.style";

//this is a custom component loader, it is a HOC to disable all children components while isOpen = true
export default function CompoenentLoader({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: ReactNode;
}) {
  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid xs={6} item sx={Styles.loaderContainer}>
        {isOpen && <CircularProgress />}
      </Grid>
      <Grid item xs={12} sx={isOpen ? Styles.disabledContainer : {}}>
        {children}
      </Grid>
    </Grid>
  );
}
