import { Box, Typography, Button } from "@mui/material";
import folderError from "../../assets/folderError.svg";
import notFoundStyles from "./notFound.style";
import { Links } from "../../common/menuItems";
import { useNavigate } from "react-router-dom";

function NotFound({ text }: { readonly text: string }) {
  const navigate = useNavigate();
  const refresh = () => {
    navigate(Links.home);
    window.location.reload();
  };
  return (
    <Box>
      <Box sx={notFoundStyles.folderIconContainer}>
        <img src={folderError} alt="notFound" />
      </Box>
      <Typography
        data-auto-test-id="not-found"
        sx={notFoundStyles.underIconText}
      >
        {text}
      </Typography>
      <Typography
        data-auto-test-id="please-refresh-text"
        sx={notFoundStyles.underIconText}
      >
        Please refresh to get the latest updates!
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={notFoundStyles.refreshButton}
          onClick={refresh}
          data-auto-test-id="refresh-button"
        >
          Refresh
        </Button>
      </Box>
    </Box>
  );
}

export default NotFound;
