import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/home/home";
import UploadOrders from "../pages/uploadOrders/uploadOrders";
import QueueDetails from "../pages/queue/queueDetails";
import { Links } from "../common/menuItems";
import {
  getPersistAuthData,
  isAuthorized,
} from "../core/services/auth/authService";
import { RolesEnum } from "../models/enums/rolesEnum";
import { useCallback, useEffect, useMemo } from "react";
import { getAllQueues } from "../core/services/queue/queueService";
import { getUserOrders } from "../core/services/order/orderService";
import { useIsAuthenticated } from "@azure/msal-react";
import QueuesGroup from "../models/queue/queueGroup";
import Reports from "../pages/reports/reports";
import { setAuthInfo } from "../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../redux/appStore";
import { hideLoader, showLoader } from "../redux/slices/loaderSlice";
import { setInitialize, setQueues } from "../redux/slices/queueSlice";
import UserOrders from "../models/order/userOrders";
import { setUserOrders } from "../redux/slices/userOrdersSlice";
import QueuesSetupPage from "../pages/queuesSetup/queuesSetupPage";
import QueueSetting from "../pages/queuesSetup/queueSetting/queueSetting";
import GroupsSetting from "../pages/groupsSettings/groupsSetting";
import SortQueues from "../pages/groupsSettings/components/sortQueues";

function AuthorizedRoutes() {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: AppState) => ({
    auth: state.authState.auth,
  }));

  const isAdmin = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Admin),
    [auth]
  );
  const isLead = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Lead),
    [auth]
  );
  const isRegularUser = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Regular),
    [auth]
  );

  useEffect(() => {
    const authData = getPersistAuthData();
    if (authData) {
      dispatch(setAuthInfo(authData));
    }
  }, [dispatch]);

  const init = useCallback(async () => {
    dispatch(showLoader());
    try {
      let queues: QueuesGroup[] = [];
      let userOrder: UserOrders | null = null;

      queues = await getAllQueues();

      if (isRegularUser) {
        userOrder = await getUserOrders();
      }

      dispatch(setUserOrders(userOrder));
      dispatch(setQueues(queues));
      dispatch(setInitialize(true));
    } finally {
      dispatch(hideLoader());
    }
  }, [isRegularUser]);

  useEffect(() => {
    if (isAuthenticated && auth?.roles && auth?.roles.length > 0) {
      init();
    }
  }, [isAuthenticated, auth, init]);

  return (
    <Routes>
      <Route path={Links.home} element={<Home />} />
      {isRegularUser || isLead || isAdmin ? (
        <Route path={`${Links.queue}/:id`} element={<QueueDetails />} />
      ) : null}
      {isAdmin ? (
        <>
          <Route path={Links.uploadOrders} element={<UploadOrders />} />
          <Route path={Links.reports} element={<Reports />} />
          <Route path={Links.queuesSetup} element={<QueuesSetupPage />} />
          <Route
            path={`${Links.queueSetting}/:id`}
            element={<QueueSetting />}
          />
          <Route path={Links.groupsSetting} element={<GroupsSetting />} />
          <Route path={`${Links.sortQueues}/:id`} element={<SortQueues />} />
        </>
      ) : null}
      {isRegularUser ? (
        <Route path="/" element={<Navigate replace to={Links.home} />} />
      ) : null}
      <Route path="*" element={<Home />} />
    </Routes>
  );
}
export default AuthorizedRoutes;
