import { Typography, Button, CircularProgress, Grid, Box } from "@mui/material";
import emptyOrders from "../../../../assets/emptyOrders.svg";
import OrderQueueFilters from "./components/orderQueueFilters/orderQueueFilters";
import OrderFilterValues from "./components/orderQueueFilters/orderFilterValues";
import UseOrderHookResult from "../../../../models/order/useOrderHookResult";
import UseQueueHookResult from "../../../../models/queue/useQueueHookResult";
import { Links } from "../../../../common/menuItems";
import { useNavigate } from "react-router-dom";
import GetNextErrorDialog from "./components/getNextErrorDialog/getNextErrorDialog";
import OrderDoneButton from "./components/orderDoneButton";
import OrderDoneDialog from "./components/orderDoneDialog";
import QueueDetailsStyles from "../../queueDetails.style";
import EmptyQueue from "./components/emptyQueue/emptyQueue";
import AppState from "../../../../redux/appStore";
import { useSelector } from "react-redux";
import SingleOrderCard from "./components/singleOrderCard";
import GroupedOrdersCards from "./components/groupedOrdersCards";
import OrderDoneDialogProps from "../../../../models/order/OrderDoneDialogProps";

export default function RegularUserCard({
  useOrderHookResult,
  useQueueHookResult,
  ModalFilterOpened,
  isRegularUser,
  isModalOpen,
  isErrorModalOpen,
  setIsErrorModalOpen,
  setIsModalOpen,
}: {
  useOrderHookResult: UseOrderHookResult;
  useQueueHookResult: UseQueueHookResult;
  ModalFilterOpened: JSX.Element | null;
  isRegularUser: boolean;
  isModalOpen: boolean;
  isErrorModalOpen: boolean;
  setIsErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const userOrders = useSelector(
    (state: AppState) => state.userOrdersState.userOrders
  );

  const OrderDoneErrorModal = isRegularUser ? (
    <GetNextErrorDialog
      isErrorModalOpen={isErrorModalOpen}
      setIsErrorModalOpen={setIsErrorModalOpen}
    />
  ) : null;

  const ChangeOrderStatus = isRegularUser ? (
    <Box sx={{ width: "100%" }}>
      <OrderDoneButton
        queueFilters={useQueueHookResult.queueFilters}
        userOrderId={
          userOrders?.userOrderList
            ? userOrders?.userOrderList[0].userOrderId
            : 0
        }
        useQueueHookResult={useQueueHookResult}
        setIsModalOpen={setIsModalOpen}
      />
    </Box>
  ) : null;

  const handleCloseDialog = () => {
    if ((useQueueHookResult.queue?.ordersCount || 0) === 0) {
      navigate(Links.home);
    }
    setIsModalOpen(false);
  };

  const orderDoneDialogProps: OrderDoneDialogProps = {
    orderNos: useOrderHookResult.orderNos,
    handleGetOrder: useOrderHookResult.handleGetOrder,
    canGetNextOrder: useQueueHookResult.queueFilters.canGetNextOrder,
    filteredOrderCount: useQueueHookResult.queueFilters.filteredOrderCount,
    isFiltersLoading: useQueueHookResult.queueFilters.isFiltersLoading,
    isSynced: useQueueHookResult.queue?.isSynced,
    ordersCount: useQueueHookResult.queue?.ordersCount,
    isLoading: useQueueHookResult.queueFilters.isLoading,
    savedUserOrderData: useQueueHookResult.queueFilters.savedUserOrderData,
    handleCloseModal: handleCloseDialog,
    isModalOpened: isModalOpen,
    queueDisabled: useQueueHookResult.queue?.disabled ?? false,
  };

  const OrderDoneModal = isRegularUser ? (
    <OrderDoneDialog orderDoneDialogProps={orderDoneDialogProps} />
  ) : null;

  const singleOrder =
    isRegularUser &&
    userOrders?.userOrderList &&
    userOrders.userOrderList.length === 1 ? (
      <SingleOrderCard
        userOrder={userOrders.userOrderList[0]}
        ChangeOrderStatus={ChangeOrderStatus}
      ></SingleOrderCard>
    ) : null;

  const groupedOrders =
    isRegularUser &&
    userOrders?.userOrderList &&
    userOrders.userOrderList.length > 1 ? (
      <GroupedOrdersCards
        userOrders={userOrders}
        ChangeOrderStatus={ChangeOrderStatus}
      ></GroupedOrdersCards>
    ) : null;

  if (
    useQueueHookResult.isLastOrderPicked || //The last order just got picked
    !useQueueHookResult.isQueueEmpty || //If the queue was not empty
    isModalOpen //To show the modal in case of last order and the queue is empty
  ) {
    return (
      <>
        {!userOrders &&
          useQueueHookResult.queue?.isSynced &&
          !useQueueHookResult.isQueueEmpty && (
            <OrderQueueFilters queueFilters={useQueueHookResult.queueFilters} />
          )}

        {userOrders && useQueueHookResult.queue?.isSynced && (
          <OrderFilterValues
            userOrderFiltersData={
              userOrders.userOrderFiltersData?.lookupData
                ? userOrders.userOrderFiltersData?.lookupData
                : []
            }
          />
        )}
        {singleOrder}
        {groupedOrders}
        {OrderDoneModal}
        {ModalFilterOpened}
        {OrderDoneErrorModal}
        {!userOrders && (
          <Grid container justifyContent={"center"} alignItems="center">
            <Grid item>
              <Grid
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                container
                sx={QueueDetailsStyles.noOrders}
              >
                <Grid item xs={12}>
                  <Box>
                    <img
                      src={emptyOrders}
                      alt="You are not working on any order now!"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    mt={1}
                    variant="h6"
                    sx={QueueDetailsStyles.userMsg}
                    data-auto-test-id="no-order-text"
                  >
                    You are not working on any order now!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item m={1}>
                      {useOrderHookResult.isLoadingNextOrder && (
                        <Button variant="contained" disabled={true}>
                          <CircularProgress size={28} />
                        </Button>
                      )}
                      {(useQueueHookResult.queueFilters.canGetNextOrder ||
                        !userOrders) &&
                        !useOrderHookResult.isLoadingNextOrder && (
                          <Button
                            sx={QueueDetailsStyles.actionButton}
                            variant="contained"
                            onClick={useOrderHookResult.handleGetOrder}
                            disabled={
                              !useQueueHookResult.queueFilters.canGetNextOrder
                            }
                            data-auto-test-id="get-next-order-button"
                          >
                            Get Next Order
                          </Button>
                        )}
                      {userOrders && ChangeOrderStatus}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  } else {
    return <EmptyQueue />;
  }
}
