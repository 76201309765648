import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { switchBorder, label, selectStyle } from "../queueSetting.style";
import QueueSettingModel from "../../../../models/status/queueSettingModel";
import { useCallback, useEffect, useState } from "react";
import { getQueueGroups } from "../../../../core/services/queueGroups/queueGroupsService";
import QueueGroupModel from "../../../../models/queue/queueGroupModel";
import formatAttribute from "../../../../common/formatAttribute";
import ValidationError from "../../../../models/status/validationError";
import { isChanged } from "../../../../redux/slices/unsavedChangesDataSlice";
import { useDispatch } from "react-redux";

export default function GeneralTabContent({
  queueSetting,
  setQueueSetting,
  validationErrors,
  requiredProps,
  setValidationErrors,
}: {
  readonly queueSetting: QueueSettingModel | undefined;
  readonly setQueueSetting: React.Dispatch<
    React.SetStateAction<QueueSettingModel | undefined>
  >;
  readonly validationErrors: ValidationError[];

  requiredProps: string[];
  setValidationErrors: any;
}) {
  const [queueGroups, setQueueGroups] = useState<QueueGroupModel[]>([]);
  const dispatch = useDispatch();
  const getAllQueueGroups = async () => {
    const groups = await getQueueGroups();
    groups.sort((a, b) => a.queueGroupName.localeCompare(b.queueGroupName));
    setQueueGroups([...groups]);
  };
  useEffect(() => {
    getAllQueueGroups();
  }, []);

  const handleSyncChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (queueSetting) {
      queueSetting.isSynced = checked;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
    }
  };
  const handleHasOrderGrouping = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (queueSetting) {
      queueSetting.hasOrderGrouping = checked;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
    }
  };

  const onGroupChanged = useCallback(
    (event: SelectChangeEvent) => {
      if (queueSetting) {
        queueSetting.queueGroupId = +event.target.value;
        setQueueSetting({ ...queueSetting });
        dispatch(isChanged(true));
      }
      const queueGroupIdProp = validationErrors.find(
        (p) => p.propName === requiredProps[0]
      );

      if (queueGroupIdProp) queueGroupIdProp.isValid = true;
      setValidationErrors([...validationErrors]);
    },
    [queueSetting]
  );

  return (
    <Box>
      <Box sx={{ marginTop: "30px" }}>
        <Typography sx={label}>Sync Orders from MR8 to Next Gen</Typography>
        <Box sx={switchBorder}>
          <Typography> Orders are Synced</Typography>
          <Switch
            data-testid={"sync-order"}
            checked={queueSetting?.isSynced ?? false}
            onChange={handleSyncChange}
            sx={{ pointerEvents: "auto" }}
            inputProps={{ "aria-label": "controlled" }}
            color={"success"}
          />
        </Box>
      </Box>
      <Box sx={{ ...selectStyle, marginTop: "30px" }}>
        <Typography sx={label}>Function Group</Typography>
        <Box>
          <Select
            fullWidth
            size="small"
            value={queueSetting?.queueGroupId?.toString() ?? "0"}
            sx={{ pointerEvents: "auto" }}
            onChange={onGroupChanged}
            data-auto-test-id="select-queue-group-drop-down"
          >
            <MenuItem key="0" disabled value="0">
              Select
            </MenuItem>
            {queueGroups.map((group) => {
              return (
                <MenuItem
                  key={group.queueGroupName}
                  value={group.queueGroupId}
                  data-auto-test-id={
                    formatAttribute(group.queueGroupName ?? "") + "-menu-item"
                  }
                >
                  {group.queueGroupName}
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            data-auto-test-id="threshold-error-text"
            component="span"
            color={"error"}
            data-testid="group-error"
          >
            {validationErrors.find((p) => p.propName === requiredProps[0])
              ?.isValid
              ? ""
              : validationErrors.find((p) => p.propName === requiredProps[0])
                  ?.message}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        <Typography sx={label}>Grouping Orders</Typography>
        <Box sx={switchBorder}>
          <Typography>
            Orders under the same CaseNo are displayed together
          </Typography>
          <Switch
            data-testid={"case-no"}
            sx={{ pointerEvents: "auto" }}
            checked={queueSetting?.hasOrderGrouping ?? false}
            onChange={handleHasOrderGrouping}
            inputProps={{ "aria-label": "controlled" }}
            color={"success"}
          />
        </Box>
      </Box>
    </Box>
  );
}
