import { get, put } from "../../http/apiClient";
import appConfig from "../../../settings/appConfig";
import QueueGroupModel from "../../../models/queue/queueGroupModel";

export const getQueueGroups = async (): Promise<QueueGroupModel[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.QueueGroups.GetAll}`
    );
    return response as QueueGroupModel[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQueueGroups = async (
  getQueueGroupModel: QueueGroupModel[]
) => {
  try {
    await put(
      `${appConfig.baseUrl}${appConfig.URLS.QueueGroups.UpdateAll}`,
      getQueueGroupModel
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
