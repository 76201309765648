import axios from "axios";
import appConfig from "../../../settings/appConfig";
import { postWithProgress } from "../../http/apiClient";
import { FileUploadModel } from "../../../models/queue/fileUpload";

export const importExcel = async (fileModel: FileUploadModel): Promise<any> => {
  // aborting the request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  try {
    const fileInfo = {
      filename: fileModel.file.name,
      totalFileSize: fileModel.file.size,
      queueId: fileModel.queueId,
    };

    const axiosTimeout = (window._env_.AXIOS_CLIENT_FILE_UPLOAD_TIMEOUT_IN_MS ||
      window._env_.AXIOS_CLIENT_TIMEOUT) as number;

    const formData = new FormData();
    formData.append("fileInfo", JSON.stringify(fileInfo));
    formData.append("file", fileModel.file);

    const response = await postWithProgress(
      `${appConfig.baseUrl}${appConfig.URLS.ImportExcel.importorders}`,
      formData,
      fileModel.progress,
      source,
      axiosTimeout
    );
    if ((response as any).success == true) {
      fileModel.load(response as any);
      return {
        success: true,
        abort: () => {
          source.cancel("Operation canceled by the user.");
          fileModel.abort();
        },
      };
    }
    throw new Error("failed to upload file");
  } catch (error: any) {
    console.error(error);
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    }

    return {
      success: false,
      abort: () => {
        source.cancel("Operation canceled by the user.");
        fileModel.abort();
      },
    };
  }
};
