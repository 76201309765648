import DOMPurify from "dompurify";

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const boldString = (str: string, substr: string) => {
  const strRegExp = new RegExp(escapeRegExp(substr), "gi");
  return str.replace(
    strRegExp,
    (match) =>
      "<b style=color:#22c55e !important;>" + DOMPurify.sanitize(match) + "</b>"
  );
};

export { boldString };
