import { useState, useEffect } from "react";
import Queue from "../models/queue/queue";
import { getQueue } from "../core/services/queue/queueService";
import { useNavigate } from "react-router-dom";
import useQueueFilters from "./useQueueFilters";
import { QueueFiltersProps } from "../models/filters/queueFiltersProps";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../redux/appStore";
import { hideLoader, showLoader } from "../redux/slices/loaderSlice";

const useQueue = ({
  id,
  setIsFilterModalOpen,
  isRegularUser,
}: {
  id: number;
  setIsFilterModalOpen: (value: boolean) => void;
  isRegularUser: boolean;
}) => {
  const [queue, setQueue] = useState<Queue | null>(null);
  const [isQueueExist, setIsQueueExistence] = useState<boolean | null>(null);
  const [isQueueEmpty, setIsQueueEmpty] = useState<boolean | null>(null);
  const [isQueueLoaded, setIsQueueLoaded] = useState(false);
  const [isLastOrderPicked, setIsLastOrderPicked] = useState(false);
  const { isLoaderOpen, userOrders, queues } = useSelector(
    (state: AppState) => ({
      isLoaderOpen: state.loaderState.isLoaderOpen,
      userOrders: state.userOrdersState.userOrders,
      queues: state.queueState.queues,
    })
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queueFilters: QueueFiltersProps = useQueueFilters({
    queue: queue ?? null,
    userOrders,
    isRegularUser: isRegularUser,
  });

  useEffect(() => {
    let hide = isQueueLoaded;
    if (hide && isLoaderOpen && queues.length > 0) {
      dispatch(hideLoader());
    }
  }, [isQueueLoaded]);

  useEffect(() => {
    setIsQueueLoaded(false);
    dispatch(showLoader());
  }, [navigate]);

  useEffect(() => {
    const checkQueueExistence = async () => {
      try {
        let response = await getQueue(id.toString());
        setIsQueueLoaded(true);

        if (response?.result) {
          let queueResult = response?.result;
          setQueue(queueResult);
          setIsQueueExistence(true);

          if (response?.result.ordersCount === 0) setIsFilterModalOpen(false);
          setIsQueueEmpty(response?.result.ordersCount === 0);
          if (
            response?.result.ordersCount === 0 &&
            userOrders &&
            +response?.result.queueId == userOrders?.queueId
          ) {
            setIsLastOrderPicked(true);
          } else {
            setIsLastOrderPicked(false);
          }
        } else if (!isLastOrderPicked) {
          setIsQueueExistence(false);
        }
      } catch (err) {
        setIsQueueExistence(false);
        throw err;
      } finally {
        setIsQueueLoaded(true);
      }
    };
    checkQueueExistence();
  }, [id, navigate, userOrders]);

  const setQueueCount = (count: number | null) => {
    setQueue({
      ...queue,
      ordersCount: count,
    } as Queue);
  };
  const setQueueDisabled = (disabled: boolean) => {
    setQueue({
      ...queue,
      disabled,
    } as Queue);
  };
  return {
    queue,
    isQueueExist,
    isQueueEmpty,
    isQueueLoaded,
    queueFilters,
    isLastOrderPicked,
    setQueueCount,
    setIsLastOrderPicked,
    setQueueDisabled,
  };
};

export default useQueue;
