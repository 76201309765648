import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import QueuesGroup from "../../../models/queue/queueGroup";
import Queue from "../../../models/queue/queue";
import { SelectedGroupQueue } from "../../../models/queue/selectedGroupQueue";
import appConfig from "../../../settings/appConfig";
import formatAttribute from "../../../common/formatAttribute";
import { useSelector } from "react-redux";
import AppState from "../../../redux/appStore";

const GroupQueueLinking = ({
  onGroupQueueChanged,
}: {
  onGroupQueueChanged: (_: SelectedGroupQueue) => void;
}) => {
  const queues = useSelector((state: AppState) => state.queueState.queues);
  const [selectedGroup, setSelectedGroup] = useState<QueuesGroup | undefined>();
  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>();
  const [activeQueueGroups, setActiveQueueGroups] = useState<QueuesGroup[]>();

  useEffect(() => {
    if (queues) {
      let activeGroups = queues.map((p) => {
        const activeQueues = p.queues.filter((k) => !k.disabled);
        const activeGroup = { ...p };
        activeGroup.queues = activeQueues;
        return activeGroup;
      });
      activeGroups = activeGroups.filter((p) => p.queues.length > 0);
      setActiveQueueGroups(activeGroups);
    }
  }, [queues]);

  const onGroupChanged = useCallback(
    (event: SelectChangeEvent) => {
      let group = activeQueueGroups?.find(
        (q) => q.queueGroupId === event.target.value
      );
      if (group) {
        setSelectedGroup({ ...group });
        setSelectedQueue(undefined);
        onGroupQueueChanged({ group: undefined, queue: undefined });
      }
    },
    [activeQueueGroups?.length]
  );

  const onQueueChanged = useCallback(
    (event: SelectChangeEvent) => {
      if (selectedGroup?.queueGroupId) {
        let queue = selectedGroup.queues.find(
          (q) => q.queueId === event.target.value
        );
        if (queue) {
          setSelectedQueue({ ...queue });
          onGroupQueueChanged({ group: selectedGroup, queue: queue });
        }
      }
    },
    [selectedGroup?.queueGroupId]
  );

  return (
    <Box sx={{ width: 500 }}>
      <Box m={2}>
        <Typography data-auto-test-id="how-spreadsheet-report">
          How do you want this spreadsheet to be reported?
        </Typography>
      </Box>
      <Box m={2}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={4}>
            <InputLabel id="select-group" data-auto-test-id="select-group">
              Select a Group
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Select
              fullWidth
              size="small"
              labelId="select-group"
              value={selectedGroup?.queueGroupId ?? ""}
              onChange={onGroupChanged}
              data-auto-test-id="select-group-drop-down"
            >
              {activeQueueGroups
                ?.filter(
                  (q) => q.queueGroupId !== appConfig.temporaryQueuesGroupId
                )
                .map((group: QueuesGroup) => (
                  <MenuItem
                    key={group.queueGroupName}
                    value={group.queueGroupId}
                    data-auto-test-id={
                      formatAttribute(group.queueGroupName ?? "") + "-menu-item"
                    }
                  >
                    {group.queueGroupName}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={4}>
            <InputLabel id="select-queue" data-auto-test-id="select-queue">
              Select a Queue
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Select
              disabled={selectedGroup?.queueGroupId === undefined}
              fullWidth
              size="small"
              labelId="select-queue"
              value={selectedQueue?.queueId ?? ""}
              onChange={onQueueChanged}
              data-auto-test-id="select-queue-drop-down"
            >
              {selectedGroup?.queues
                ?.filter((q) => q.isSynced)
                .map((queue: Queue) => (
                  <MenuItem
                    data-auto-test-id={
                      formatAttribute(queue.name) + "-menu-item"
                    }
                    key={queue.name}
                    value={queue.queueId}
                  >
                    {queue.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default GroupQueueLinking;
