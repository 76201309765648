import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import RemainingOrdersPanel from "./components/remainingOrdersPanel";
import StyledTab from "../../../../common/styledTab";
import TabPanel from "../../../../common/tabPanel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Queue from "../../../../models/queue/queue";

export default function AdminView({
  queue,
}: {
  queue: Queue | null | undefined;
}) {
  const [value, setValue] = useState(0);
  const { id } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (queue?.isSynced !== true) {
      setValue(0);
    }
  }, [id, queue]);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <StyledTab
            data-auto-test-id="remaining-orders-tab"
            label="Remaining Orders"
            disabled={false}
          />
        </Tabs>
      </Box>
      <TabPanel
        data-auto-test-id="remaining-orders-panel"
        value={value}
        index={0}
      >
        <RemainingOrdersPanel queueId={queue?.queueId} />
      </TabPanel>
    </Box>
  );
}
