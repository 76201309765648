import React from "react";
import Box from "@mui/material/Box";
import NavBar from "./components/navBar/navBar";
import SideBar from "./components/sideBar/sideBar";
import { createTheme, ThemeProvider } from "@mui/material";
import { lightTheme } from "../common/theme";

export const PageLayout = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const theme = createTheme(lightTheme);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <NavBar />
        <SideBar open={true} />
        <Box component="main" sx={{ flexGrow: 1, padding: "80px 30px" }}>
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
