import { useCallback, useEffect, useMemo, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { GroupItem } from "../../../../../../../../models/sideBar/groupItem";
import { Badge, Grid, Popover, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/system";
import { matchPath } from "react-router";
import { Links } from "../../../../../../../../common/menuItems";
import { isAuthorized } from "../../../../../../../../core/services/auth/authService";
import { RolesEnum } from "../../../../../../../../models/enums/rolesEnum";
import { QueueVolumeIndicator } from "../../../../../../../../models/enums/queueVolumeIndicator";
import appConfig from "../../../../../../../../settings/appConfig";
import Styles from "../../../../../sideBar.style";
import formatAttribute from "../../../../../../../../common/formatAttribute";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../../../../../../redux/appStore";
import { showSnackbar } from "../../../../../../../../redux/slices/snackbarSlice";
import { openConfirmModal } from "../../../../../../../../redux/slices/unsavedChangesDataSlice";
import { ModalActionType } from "../../../../../../../../models/store/unsavedChangesDataState";

export default function SideBarItem({ item }: { item: GroupItem }) {
  const [volumeColor, setVolumeColor] = useState<string>("");
  const dispatch = useDispatch();

  const theme = useTheme();
  const location = useLocation();

  const navigate = useNavigate();

  const { auth, userOrders, isChanged } = useSelector((state: AppState) => ({
    auth: state.authState.auth,
    userOrders: state.userOrdersState.userOrders,
    isChanged: state.unsavedChangesDataState.isChanged,
  }));

  const progressOrderMessage =
    "You’ve an order in progress, Please complete it before leaving this page";

  const isActive = !!matchPath(location.pathname, item.navigateTo ?? "");

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isAdmin = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Admin),
    [auth]
  );
  const isLead = useMemo(
    () => isAuthorized(auth?.roles ?? [], RolesEnum.Lead),
    [auth]
  );
  const canNavigateToUnassignedQueue = useMemo(
    () => isAdmin || isLead,
    [isAdmin, isLead]
  );

  const canNavigate = () => {
    if (
      !canNavigateToUnassignedQueue &&
      (item?.navigateTo ?? "").indexOf(Links.queue) > -1 &&
      userOrders?.userOrderList &&
      userOrders.queueId != Number(item.id)
    ) {
      return false;
    }
    return true;
  };

  function handleClick(item: GroupItem) {
    if (isChanged) {
      let modalConfirmedActionType: ModalActionType = ModalActionType.navigate;
      let modalConfirmedActionData = item.navigateTo ?? "";

      if (!canNavigate()) {
        modalConfirmedActionType = ModalActionType.showSnackbar;
        modalConfirmedActionData = progressOrderMessage;
      }
      dispatch(
        openConfirmModal({
          modalConfirmedActionType: modalConfirmedActionType,
          modalConfirmedActionData: modalConfirmedActionData,
        })
      );
    } else {
      handleClickAfterConfirm();
    }
  }

  const handleClickAfterConfirm = () => {
    if (!canNavigate()) {
      dispatch(showSnackbar(progressOrderMessage));
      return;
    }
    navigate(item.navigateTo ?? "");
  };

  const getColorOfQueue = useCallback(() => {
    let indicator = QueueVolumeIndicator.Normal;
    let color = theme.palette?.success["500"];

    if (!item.isSynced) {
      color = theme.palette?.neutral["200"];
    } else if (item.count) {
      if (item.max && item.count > item.max) {
        indicator = QueueVolumeIndicator.AboveMax;
        color = theme.palette?.error["500"];
      } else if (item.min && item.count < item.min) {
        indicator = QueueVolumeIndicator.BelowMin;
        color = theme.palette?.warning["500"];
      }
    } else {
      indicator = QueueVolumeIndicator.Empty;
      color = theme.palette?.success["500"];
    }

    setVolumeColor(color);
  }, [item, theme.palette]);

  useEffect(() => {
    getColorOfQueue();
  }, [item, theme.palette]);

  const activeStyle = isActive ? Styles.activeSideBarButton : {};
  return (
    <ListItem key={item.title} disablePadding data-testid="sidebar-item">
      <ListItemButton
        onClick={() => handleClick(item)}
        data-auto-test-id={formatAttribute(item.title ?? "") + "-menu-item"}
        sx={{ ...Styles.sideBarButton, ...activeStyle }}
        data-testid="sidebar-item-button"
      >
        <Grid width={"500px"} container justifyContent="flex-end">
          <Grid item xs={item.count || item.count == 0 ? 11 : 12}>
            <ListItemText
              aria-owns={open ? "mouse-over-popover" : undefined}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              primary={
                (item.title?.length ?? 0) > appConfig.maxSideBarCharLength
                  ? item.title
                      ?.substring(0, appConfig.maxSideBarCharLength)
                      .concat("...")
                  : item.title
              }
              data-auto-test-id={
                formatAttribute(item.title ?? "") + "-menu-item-text"
              }
            />
            {item.isQueue && (
              <Popover
                id="mouse-over-popover"
                sx={Styles.popover}
                PaperProps={{ sx: Styles.popoverPaper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {item.title}
              </Popover>
            )}
          </Grid>
          {(item.count || item.count == 0) && (
            <Grid item xs={item.count || item.count == 0 ? 1 : 0}>
              <Badge
                badgeContent={
                  <Typography
                    variant="subtitle2"
                    data-auto-test-id={
                      formatAttribute(item.title ?? "") +
                      "-menu-item-count-badge"
                    }
                  >
                    {item.count?.toLocaleString("en-US")}
                  </Typography>
                }
                style={{ wordWrap: "break-word" }}
                sx={Styles.badge(volumeColor)}
                max={Number.MAX_SAFE_INTEGER}
                data-testid="queue-badge"
              />
            </Grid>
          )}
        </Grid>
      </ListItemButton>
    </ListItem>
  );
}
