import { SxProps } from "@mui/material";

export const tableRow: SxProps = {
  color: (theme: any) => theme.palette.neutral["600"],
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px !important",
  lineHeight: "24px",
};

export const tableHeader: SxProps = {
  color: (theme: any) => theme.palette.neutral["400"],
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "16px",
};

export const tableContainer: SxProps = {
  border: "1px solid",
  borderRadius: "8px",
  marginTop: "20px",
  borderColor: (theme: any) => theme.palette.neutral["200"],
};

export const editColor: SxProps = {
  ...tableRow,
  color: (theme: any) => theme.palette.primary["500"],
  display: "flex",
  justifyContent: "flex-start",
};
