import { RolesEnum } from "../../../models/enums/rolesEnum";
import { tokenRequest } from "../../../settings/authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";
import jwt from "jwt-decode";
import { IAuthData } from "../../../models/user/authData";
import { JwtUserData } from "../../../models/user/jwtUserData";
import appConfig from "../../../settings/appConfig";
import UserRole from "../../../models/user/userRole";

let isAcquiredTokenPopup = false;

export async function acquireTokenSilent(instance: IPublicClientApplication) {
  let accessToken = null;

  const request = {
    ...tokenRequest,
    account: instance.getAllAccounts()[0],
  };
  try {
    let tokenResponse = await instance.acquireTokenSilent(request);
    accessToken = tokenResponse.accessToken;
  } catch (error) {
    console.error(error);
  }
  return accessToken;
}

export async function acquireTokenPopup(instance: IPublicClientApplication) {
  let accessToken = null;

  const request = {
    ...tokenRequest,
    account: instance.getAllAccounts()[0],
  };
  try {
    let tokenResponse = await instance.acquireTokenPopup(request);
    accessToken = tokenResponse.accessToken;
  } catch (error) {
    console.error(error);
  }
  return accessToken;
}

export const logoutUser = async (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    account: instance.getAllAccounts()[0],
    postLogoutRedirectUri: window._env_.MSAL_AUTH_REDIRECT_URI,
    onRedirectNavigate: () => {
      return true;
    },
  });
};

export const refreshToken = async (instance: IPublicClientApplication) => {
  let accessToken = (await acquireTokenSilent(instance)) as string;

  if (
    (accessToken === null || accessToken === undefined) &&
    !isAcquiredTokenPopup
  ) {
    isAcquiredTokenPopup = true;
    try {
      accessToken = (await acquireTokenPopup(instance)) as string;
    } catch {
      await logoutUser(instance);
    }
    if (accessToken === null || accessToken === undefined) {
      await logoutUser(instance);
    }
  } else {
    await setTimeout(() => {}, 1000);
  }

  const authData = getPersistAuthData();
  const prev: IAuthData | undefined = authData;
  if (prev !== undefined) {
    prev.token = accessToken;
    persistAuthData(prev);
  }
  return { ...prev, accessToken };
};

export const parseJwt = async (accessToken: string) => {
  const userData = jwt(accessToken as string) as JwtUserData;

  const authData: IAuthData = {
    userId: 0,
    azureId: userData.oid,
    userName: userData.name,
    email: userData.preferred_username,
    token: accessToken as string,
    roles: [],
  };
  return authData;
};

export const getPersistAuthData = (): IAuthData => {
  const authJson = localStorage.getItem(appConfig.authKey);
  return JSON.parse(authJson as string);
};

export const persistAuthData = (data: IAuthData) => {
  localStorage.setItem(appConfig.authKey, JSON.stringify(data) as string);
};

export const removeAuthData = () => {
  localStorage.removeItem(appConfig.authKey);
};

export const isAuthorized = (roles: UserRole[], role: RolesEnum) => {
  return roles.find((o) => o.roleId == role) ? true : false;
};
