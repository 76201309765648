// Styles.ts
import { SxProps } from "@mui/material";

const progress: SxProps = {
  display: "flex",
};

const inProgressButton: SxProps = {
  color: "green",
  padding: 2,
  border: "solid 1px",
  float: "right",
  borderRadius: 4,
};

const pagination: SxProps = {
  padding: "10px",
  backgroundColor: (theme: any) => theme.palette.primary["100"],
};

const tableRow: SxProps = {
  "&:last-child td, &:last-child th": { border: 0 },
};

const tableHeader: SxProps = {
  backgroundColor: (theme: any) => theme.palette.primary["100"],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const orderNo: SxProps = {
  color: (theme: any) => theme.palette.primary["500"],
};

const orderNoDisabled: SxProps = {
  color: (theme: any) => theme.palette.primary["100"],
};

const filterContainerItem: SxProps = {
  backgroundColor: (theme: any) => theme.palette.primary["100"],
  borderRadius: "50px",
};

const filterContainer: SxProps = {
  minWidth: "500px",
};

const badge: SxProps = {
  borderRadius: "15px",
  px: 1,
  py: "4px",
  backgroundColor: (theme: any) => theme.palette.primary["500"],
  color: (theme: any) => theme.palette.common.white,
};

const contentContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  minWidth: "350px",
  minHeight: "100px",
};

const actionsContainer: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
};

const actionButton: SxProps = {
  color: (theme: any) => theme.palette.common.white,
  "&:disabled": {
    color: (theme: any) => theme.palette.common.white,
  },
};

const greyCard: SxProps = {
  backgroundColor: (theme: any) => theme.palette.neutral["100"],
  marginTop: "25px",
  marginBottom: "5px",
  padding: "6px 6px",
  borderRadius: "7px",
};

const noOrders: SxProps = {
  padding: "6px 0px",
  mt: "50px",
};

const queueDetailsMainContainer: SxProps = {
  padding: "25px",
};

const userMsg: SxProps = {
  color: (theme: any) => theme.palette.neutral["500"],
};

const currentOrderNo: SxProps = {
  color: (theme: any) => theme.palette.neutral["800"],
};

const card: SxProps = {
  minWidth: 275,
  marginTop: 1,
  borderColor: (theme: any) => theme.palette.neutral["200"],
  border: "1px",
  borderRadius: "8px",
};

const groupCard: SxProps = {
  marginRight: 2,
  marginBottom: 2,
};
const orderActionsContainer: SxProps = {
  margin: "0 16px 16px 16px",
};

const actionButtonGrouped: SxProps = {
  display: "inline-flex",
  float: "right",
};
const ordersBox: SxProps = {
  padding: "21px 25px",
  border: "1px",
  borderRadius: "8px",
  borderColor: (theme: any) => theme.palette.neutral["400"],
  marginTop: 2,
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
};
const caseNoTxt: SxProps = {
  margin: "25px 0px 35px 0px",
  color: (theme: any) => theme.palette.neutral["500"],
};
const caseNo: SxProps = {
  marginRight: 5,
  fontWeight: "bold",
  color: (theme: any) => theme.palette?.primary["400"],
};
const partsNo: SxProps = {
  marginRight: 3,
  fontWeight: "bold",
  color: (theme: any) => theme.palette?.primary["400"],
};
const classes = {
  contentContainer,
  actionsContainer,
  actionButton,
  orderNo,
  orderNoDisabled,
  tableHeader,
  tableRow,
  pagination,
  inProgressButton,
  progress,
  filterContainer,
  filterContainerItem,
  badge,
  greyCard,
  noOrders,
  userMsg,
  currentOrderNo,
  card,
  groupCard,
  queueDetailsMainContainer,
  orderActionsContainer,
  actionButtonGrouped,
  ordersBox,
  caseNoTxt,
  caseNo,
  partsNo,
};

export default classes;
