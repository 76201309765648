import { createSlice } from "@reduxjs/toolkit";
import { persistAuthData } from "../../core/services/auth/authService";
import { defaultAuthData, IAuthData } from "../../models/user/authData";

interface AuthState {
  auth: IAuthData | undefined;
}

const initialState: AuthState = {
  auth: defaultAuthData,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      const authData: IAuthData = {
        userId: action.payload.userId,
        azureId: action.payload.azureId,
        userName: action.payload.userName,
        email: action.payload.email,
        token: action.payload.token,
        roles: action.payload.roles,
      };
      persistAuthData(authData);
      state.auth = authData;
    },
    clearAuthInfo: (state) => {
      state.auth = defaultAuthData;
    },
  },
});

export const { setAuthInfo, clearAuthInfo } = authSlice.actions;
export default authSlice.reducer;
