import axiosClient from "./axiosClient";

export async function get<TResponse>(
  path: string,
  timeout?: number
): Promise<TResponse> {
  try {
    const response = await axiosClient.get<TResponse>(path, {
      timeout: timeout ?? window._env_.AXIOS_CLIENT_TIMEOUT,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function post<TRequest, TResponse>(
  path: string,
  payload: TRequest,
  timeout?: number
): Promise<TResponse> {
  try {
    const response = await axiosClient.post<TResponse>(path, payload, {
      timeout: timeout ?? window._env_.AXIOS_CLIENT_TIMEOUT,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function postWithProgress<TRequest, TResponse>(
  path: string,
  payload: TRequest,
  progress: any,
  source: any,
  timeout: number
): Promise<TResponse> {
  try {
    const response = await axiosClient.post<TResponse>(path, payload, {
      onUploadProgress: (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      },
      cancelToken: source.token,
      timeout,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function patch<TRequest, TResponse>(
  path: string,
  payload: TRequest,
  timeout?: number
): Promise<TResponse> {
  try {
    const response = await axiosClient.patch<TResponse>(path, payload, {
      timeout: timeout ?? window._env_.AXIOS_CLIENT_TIMEOUT,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function put<TRequest, TResponse>(
  path: string,
  payload: TRequest,
  timeout?: number
): Promise<TResponse> {
  try {
    const response = await axiosClient.put<TResponse>(path, payload, {
      timeout: timeout ?? window._env_.AXIOS_CLIENT_TIMEOUT,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
