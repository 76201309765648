import { PageLayout } from "./layout/pageLayout";
import { BrowserRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Loader from "./common/loader/loader";
import { createTheme, ThemeProvider } from "@mui/material";
import { lightTheme } from "./common/theme";
import "./css/App.css";
import { MessageSnackbar } from "./common/messageSnackbar";
import AuthorizedRoutes from "./routes/authorizedRoutes";
import UnauthorizedRoutes from "./routes/unauthorizedRoutes";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { hideSnackbar } from "./redux/slices/snackbarSlice";
import AppState from "./redux/appStore";
import UnsavedChangesDialog from "./common/unsavedChangesDialog/unsavedChangesDialog";

function App() {
  const dispatch = useDispatch();
  const { isLoaderOpen, snackBarState } = useSelector((state: AppState) => ({
    isLoaderOpen: state.loaderState.isLoaderOpen,
    snackBarState: state.snackbarState,
  }));

  const handleCloseSnackBar = () => {
    dispatch(hideSnackbar());
  };

  const theme = createTheme(lightTheme);

  return (
    <div className="App">
      <MessageSnackbar
        isOpen={snackBarState?.showSnackBar}
        handleClose={handleCloseSnackBar}
        message={snackBarState?.snackBarMessage}
        severity={snackBarState?.severity}
      ></MessageSnackbar>
      <Loader isOpen={isLoaderOpen} />
      <AuthenticatedTemplate>
        <BrowserRouter>
          <PageLayout>
            <UnsavedChangesDialog />
            <AuthorizedRoutes />
          </PageLayout>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <UnauthorizedRoutes />
          </ThemeProvider>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </div>
  );
}
export default App;
